import { Basic } from './device-platform';
import { AllowedDevicePlatformsType, IDevicePlatform } from './device-platform.interface';
import { Movistar } from './movistar-device';
import { NetCast } from './netcast-device';
import { Tizen } from './tizen-device';
import { Vizio } from './vizio-device';
import { Webos } from './webos-device';
import { Zeasn } from './zeasn-device';

const devicePlatformFactory = async (type: string, force: boolean): Promise<IDevicePlatform> => {
  let devicePlatform: IDevicePlatform;
  switch (type) {
    case AllowedDevicePlatformsType.basic: {
      devicePlatform = new Basic();
      devicePlatform.setNameWithOperatingSystem();
      break;
    }
    case AllowedDevicePlatformsType.vizio: {
      devicePlatform = new Vizio(force);
      break;
    }
    case AllowedDevicePlatformsType.movistar: {
      devicePlatform = new Movistar(force);
      break;
    }
    case AllowedDevicePlatformsType.netcast: {
      devicePlatform = new NetCast(force);
      break;
    }
    case AllowedDevicePlatformsType.webos: {
      devicePlatform = new Webos(force);
      break;
    }
    case 'samsung': {
      devicePlatform = new Tizen(force);
      break;
    }
    case AllowedDevicePlatformsType.tizen: {
      devicePlatform = new Tizen(force);
      break;
    }
    case AllowedDevicePlatformsType.zeasn: {
      devicePlatform = new Zeasn(force);
      break;
    }
    default: {
      devicePlatform = new Basic();
      devicePlatform.setNameWithOperatingSystem();
      return devicePlatform;
    }
  }
  await devicePlatform.onInit();
  return devicePlatform;
};

export { devicePlatformFactory };
