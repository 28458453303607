import { Injectable } from '@angular/core';
import { IMedia } from '@interfaces/real-time-smart-list.interface';

import { MetricsService } from '../metrics/metrics.service';

export type SearchScope = 'textual' | 'recommended' | 'firstSong';
export const searchScope: {
  textual: 'textual';
  recommended: 'recommended';
  firstSong: 'firstSong';
} = {
  textual: 'textual',
  recommended: 'recommended',
  firstSong: 'firstSong',
};

export interface ISuccessfulSearchEventInterface {
  action: string;
  panel: string;
  artist: any;
  position: any;
  keyword: any;
  song: any;
  song_id?: any;
  name?: any;
  song_name?: any;
  row_index?: any;
  playlist: any;
  success: boolean;
  // [match textual] o [por recomendación] o [primera canción dentro del playlist] (en el caso de la columna de canciones o artistas)
  scope: SearchScope;
}

@Injectable()
export class SearchAnalyzerService {
  lastAction;
  lastSearchField;
  lastPanel;
  lastIndex;
  lastPlaylist;
  latestSuccess = false;

  processedKeywords: string[] = [];
  successfulKeywords: string[] = [];
  failedKeywords: string[] = [];

  constructor(public metrics: MetricsService) {}

  onSearch(data: ISuccessfulSearchEventInterface) {
    this.metrics.sendEvent('searched', data);
  }

  proccessSuccesfulSearch(
    action: string,
    success: boolean,
    searchField: string,
    event: ISuccessfulSearchEventInterface,
    song?: IMedia
  ) {
    const eventData: ISuccessfulSearchEventInterface = {
      action: action || this.lastAction,
      panel: event?.panel || this.lastPanel,
      artist: event?.artist || song?.artist || event?.name,
      position: event?.panel === 'songs' ? event.row_index : this.lastIndex,
      keyword: searchField || this.lastSearchField,
      song: event?.panel === 'songs' ? event.name : song ? song.name : undefined,
      song_id: song ? song.id : undefined,
      playlist: event?.playlist || this.lastPlaylist,
      success,
      scope: searchScope.textual,
    };
    this.lastAction = action || this.lastAction;
    this.lastSearchField = searchField || this.lastSearchField;
    this.lastPanel = eventData.panel || this.lastPanel;
    this.latestSuccess = true;
    if (this.processedKeywords.indexOf(this.lastSearchField) === -1) {
      this.processedKeywords.push(this.lastSearchField);
      this.onSearch(eventData);
    }
  }

  processFailedSearch(action: string, keyword: string) {
    const eventData: ISuccessfulSearchEventInterface = {
      action,
      panel: undefined,
      artist: undefined,
      position: undefined,
      keyword: keyword || this.lastSearchField,
      song: undefined,
      playlist: this.lastPlaylist,
      success: false,
      scope: searchScope.textual,
    };
    this.lastAction = action;
    this.lastSearchField = keyword || this.lastSearchField;
    this.lastPanel = eventData.panel || this.lastPanel;
    this.latestSuccess = false;
    if (this.processedKeywords.indexOf(this.lastSearchField) === -1) {
      this.processedKeywords.push(this.lastSearchField);
      this.onSearch(eventData);
    }
  }

  updateLastAction(action: string, searchField: string, panel: string, index: number, playlist?) {
    this.lastAction = action;
    this.lastSearchField = searchField;
    this.lastPanel = panel;
    this.lastPlaylist = playlist;
    this.lastIndex = index;
  }
}
