import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { SessionService } from '@providers/user/session.service';

import { Api } from '../api/api';
import { Config } from '../config/config';
import { MetricsService } from '../metrics/metrics.service';
import { User } from '../user/user';

@Injectable()
export class RegisterWebService {
  constructor(
    private sessionService: SessionService,
    private userService: User,
    private api: Api,
    private config: Config,
    private metrics: MetricsService
  ) {}

  initSessionByQR(qr) {
    console.log('initSessionByQR', qr);

    return this.api
      .get('session', { code: qr }, environment.apiExtraHeaders)
      .toPromise()
      .then(async res => {
        if (!res.success) {
          this.metrics.sendEvent('errorIngresa', {
            message: res.message,
            mode: 'web',
          });
          return false;
        }
        res = User.transformFromResponseData(res);
        if (res.success) {
          const whereSession = 'web';
          const userSession = res.data;
          // actualizando plans
          this.config.confData = this.config.confData ? this.config.confData : {};
          this.config.confData.plans = userSession.plans;

          if (!this.userService.authenticated) {
            await this.userService.initUser(userSession, whereSession);
          } else if (this.userService.currentUser.id !== userSession.id) {
            await this.userService.initUser(res.data, whereSession);
          } else {
            // User is already logged in
            // nothing to do
          }
          if (res.success && userSession.premium) {
            this.userService.updateToPremium();
          }

          this.sessionService.setSessionData(userSession);
          this.metrics.identify(userSession, userSession.id);
        }
        this.userService.userLoginOK.next(true);
        return true;
      });
  }
}
