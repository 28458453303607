import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { Platform } from '../platform/platform';

declare interface Document {
  cookie: string;
}

/**
 * Based on NGRX COOKIES
 */

@Injectable()
export class CookiesService {
  public document: Document;
  public cookies = {};
  private readonly documentIsAccessible: boolean;
  constructor(private injector: Injector, private platform: Platform, @Inject(REQUEST) public request: any) {
    this.documentIsAccessible = this.platform.isBrowser() || this.platform.isServer();
    if (this.platform.isServer()) {
      this.document = { cookie: this.request.headers['cookie'] || '' };
    } else if (this.platform.isBrowser()) {
      this.document = this.injector.get(DOCUMENT);
    }
    if (this.documentIsAccessible) {
      this.cookies = this.getAll();
    }
  }
  /**
   * @param name Cookie name
   * @returns
   */
  check(name: string): boolean {
    if (!this.documentIsAccessible) {
      return false;
    }

    name = encodeURIComponent(name);

    const regExp: RegExp = this.getCookieRegExp(name);
    const exists: boolean = regExp.test(this.document.cookie);

    return exists;
  }
  /**
   * @param name Cookie name
   * @returns
   */
  get(name: string): string {
    if (this.documentIsAccessible && this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(this.document.cookie);

      return decodeURIComponent(result[1]);
    } else {
      return '';
    }
  }

  /**
   * @returns {}
   */
  getAll() {
    if (!this.documentIsAccessible) {
      return {};
    }

    const cookies: unknown = {};
    const document: any = this.document;

    if (document.cookie && document.cookie !== '') {
      const split: string[] = document.cookie.split(';');

      for (const item of split) {
        const currentCookie: string[] = item.split('=');

        currentCookie[0] = currentCookie[0].replace(/^ /, '');
        cookies[decodeURIComponent(currentCookie[0])] = decodeURIComponent(currentCookie[1]);
      }
    }

    return cookies;
  }

  /**
   * @param name     Cookie name
   * @param value    Cookie value
   * @param expires  Number of days until the cookies expires or an actual `Date`
   * @param path     Cookie path
   * @param domain   Cookie domain
   * @param secure   Secure flag
   * @param sameSite OWASP samesite token `Lax` or `Strict`
   */
  set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'Strict'
  ): void {
    if (!this.documentIsAccessible) {
      return;
    }

    let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    if (expires) {
      if (typeof expires === 'number') {
        const dateExpires: Date = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + expires.toUTCString() + ';';
      }
    }

    if (path) {
      cookieString += 'path=' + path + ';';
    }

    if (domain) {
      cookieString += 'domain=' + domain + ';';
    }

    if (secure) {
      cookieString += 'secure;';
    }

    if (sameSite) {
      cookieString += 'sameSite=' + sameSite + ';';
    }

    this.cookies[name] = value;
    if (this.platform.isBrowser()) {
      this.document.cookie = cookieString;
    } else if (this.platform.isServer()) {
      const cookieRegex = new RegExp(`${name}[=].{1,}?([;]|$)`);
      if (this.document.cookie.search(cookieRegex) !== -1) {
        this.document.cookie.replace(cookieRegex, cookieString);
      } else {
        this.document.cookie += cookieString;
      }
    } else {
      this.document.cookie = cookieString;
    }
  }

  /**
   * @param name   Cookie name
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  delete(name: string, path?: string, domain?: string): void {
    if (!this.documentIsAccessible) {
      return;
    }

    this.set(name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain);
  }

  /**
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  deleteAll(path?: string, domain?: string): void {
    if (!this.documentIsAccessible) {
      return;
    }

    const cookies: any = this.getAll();

    for (const cookieName in cookies) {
      if (cookies.hasOwnProperty(cookieName)) {
        this.delete(cookieName, path, domain);
      }
    }
  }

  /**
   * @param name Cookie name
   * @returns
   */
  private getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');

    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }
}
