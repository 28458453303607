import { ABTestingService } from './ab-testing/ab-testing.service';
import { Alertify } from './alertify/alertify';
import { Api } from './api/api';
import { ArtistService } from './artist/artist';
import { Config } from './config/config';
import { CookieService } from './cookies/cookies';
import { CookiesService } from './cookies/cookies.service';
import { CountryService } from './country/country';
import { DeviceKS } from './device-ks/device-ks';
import { FacebookLoginProvider } from './facebook/facebook-login';
import { FirebaseItemsInterface } from './firebase/firebaseItemsInterface';
import { KSFirebase } from './firebase/ksfirebase';
import { FriendsService } from './friends/friends';
import { GtvZone } from './gtv/gtv';
import { KSHelpers } from './helpers/ks-helpers';
import { LocationService } from './helpers/location';
import { TranslateInitializer } from './i18n/translate.initializer';
import { LocalStorage } from './local-storage/local-storage';
import { HotjarService } from './metrics/hotjar/hotjar.service';
import { MetricsService } from './metrics/metrics.service';
import { ModalService } from './modal/modal';
import { Mxp } from './mxp/mxp';
import { Notifications } from './notifications/notifications';
import { Platform } from './platform/platform';
import { PlaylistService } from './playlist/playlist';
import { PreviousRouteService } from './previus-route/previus-route.service';
import { ProfilesArtistService } from './profiles-artist/profiles-artist.service';
import { RankingService } from './ranking/ranking';
import { RecordsService } from './records/records.service';
import { RegisterWebService } from './registerWeb/registerWeb';
import { RootScopeAdapter } from './root-scope-adapter/root-scope-adapter';
import { SearchAnalyzerService } from './search/search-analyzer.service';
import { SmartListService } from './smartlist/smart-list.service';
import { SmsService } from './sms/sms';
import { SongsService } from './songs/songs';
import { AuthTokenService } from './user/auth-token.service';
import { PremiumCheckerService } from './user/premium-checker.service';
import { SessionService } from './user/session.service';
import { User } from './user/user';
import { UserRealtimeService } from './user/user-realtime';

export {
  ABTestingService,
  Api,
  PreviousRouteService,
  SmartListService,
  AuthTokenService,
  ArtistService,
  CookieService,
  CookiesService,
  CountryService,
  FirebaseItemsInterface,
  FacebookLoginProvider,
  KSHelpers,
  KSFirebase,
  LocalStorage,
  LocationService,
  PlaylistService,
  RankingService,
  TranslateInitializer,
  RegisterWebService,
  RootScopeAdapter,
  SearchAnalyzerService,
  SmsService,
  RecordsService,
  SongsService,
  Mxp,
  HotjarService,
  MetricsService,
  Notifications,
  Config,
  DeviceKS,
  FriendsService,
  GtvZone,
  SessionService,
  PremiumCheckerService,
  ModalService,
  User,
  ProfilesArtistService,
};

export const boostrapTestingProviders = [
  Alertify,
  Api,
  AuthTokenService,
  Config,
  PreviousRouteService,
  CookieService,
  CookiesService,
  CountryService,
  DeviceKS,
  KSHelpers,
  LocalStorage,
  LocationService,
  Mxp,
  HotjarService,
  MetricsService,
  Platform,
  // PlaylistService,
  RootScopeAdapter,
  SessionService,
  PremiumCheckerService,
  User,
  UserRealtimeService,
];
