import { Injectable } from '@angular/core';
import { KantoAd } from '@interfaces/kanto-ad.interface';
import {
  collection,
  CollectionReference,
  doc,
  Firestore,
  mainCollection,
} from '@providers/firebase/firestore.functions';
import { collectionFromOnSnapshot, mapCollectionFromQuerySnapshot } from '@providers/helpers/firestore';
import { SongCounterService } from '@providers/song-counter/song-counter';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TranslateInitializer } from '../i18n/translate.initializer';

@Injectable()
export class AdsService {
  private ads: KantoAd[] = [];
  private iWasOmitedAd = false;
  constructor(
    private firestore: Firestore,
    private songCounter: SongCounterService,
    private translateService: TranslateInitializer
  ) {
    // console.log('se crea una instancia de ads service', this.ads, this.sungSongs);
  }

  private get sungSongs() {
    return this.songCounter.getSungSongs();
  }

  singSong() {
    this.iWasOmitedAd = false;
    this.songCounter.singSong();
  }

  getSungSongs() {
    return this.songCounter.getSungSongs();
  }

  setSungSongs(value) {
    return this.songCounter.setSungSongs(value);
  }

  omitAd() {
    this.iWasOmitedAd = true;
  }

  mustBeShowAd() {
    return this.sungSongs > 0 && this.sungSongs % 2 === 0 && !this.iWasOmitedAd;
  }

  getAds(): Observable<KantoAd[]> {
    if (this.ads.length > 0) {
      return of(this.ads);
    } else {
      const currentLang = this.translateService.getCurrentLang();
      const langsAvailables = ['es', 'en', 'pt'];
      const adsCollection = mainCollection(this.firestore, 'ads');
      const langsAvailablesDoc = doc(adsCollection, langsAvailables.indexOf(currentLang) !== -1 ? currentLang : 'en');
      const videosColection = collection(langsAvailablesDoc, 'videos') as CollectionReference<KantoAd>;
      return collectionFromOnSnapshot(videosColection)
        .pipe(map(mapCollectionFromQuerySnapshot))
        .pipe(
          take(1),
          map(response => {
            this.ads = response;
            return this.ads;
          })
        );
    }
  }

  checkIfAdIsValid(ad: KantoAd): boolean {
    const hasSrc = ad.src !== undefined && ad.src !== '';
    const hasId = ad.id !== undefined && ad.id !== '';
    return hasSrc && hasId;
  }

  getOneAd(): KantoAd {
    const index = Math.floor(Math.random() * this.ads.length);
    this.iWasOmitedAd = false;
    const adFound = this.ads[index];
    if (this.checkIfAdIsValid(adFound)) {
      return adFound;
    } else {
      return this.getOneAd();
    }
  }
}
