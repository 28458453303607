import { UserSessionResponseInterface } from 'src/interfaces/interfaces';

import { CreditCardInterface } from './credit-card.interface';

export const getCardName = (credit_card: CreditCardInterface, user: UserSessionResponseInterface) => {
  let cardName = '';
  try {
    cardName = (credit_card.first_name || credit_card.last_name).replace(/[^a-zA-Z]/g, '');
    cardName = nameFromUser(user);
  } catch (e) {
    cardName = 'NODATANAME';
  }

  return cardName;
};

export const maskCreditCard = (number: string = '') => {
  const reg = /(?!\b.{4}[ -])(?:[ -]?\d{4}[ -]?){2}(?=\d{4}\b)/;
  return number.replace(reg, '********');
};

export const nameFromUser = (user: UserSessionResponseInterface): string => {
  const mailUsername = user.email.match(/^[^@]+/g);
  let name = '';
  try {
    if (!name) {
      name =
        (user.first_name ? user.first_name.replace(/[^a-zA-Z]/g, '') : '') ||
        (mailUsername ? mailUsername[0].replace(/[^a-zA-Z]/g, '') : 'NODATANAME');
    }
  } catch (e) {
    name = 'NODATANAME';
  }

  if (name.length === 0) {
    name = 'NODATANAME';
  }

  return name;
};
