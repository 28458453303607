import { Injectable } from '@angular/core';

import { AllowedDevicePlatforms, AllowedDevicePlatformsType } from './device-platform.interface';

@Injectable()
export class DeviceDetectorService {
  checkIfIsVizio(): boolean {
    const userAgent = navigator.userAgent;
    const substrPos = userAgent.search(/VIZIO/i);
    const hasVIzioLib = !!(window as any).VIZIO;
    if (substrPos > -1 || hasVIzioLib) {
      return true;
    } else {
      return false;
    }
  }

  checkIfIsZeans(): boolean {
    const userAgent = navigator.userAgent;
    const substrPos = userAgent.search(/SmartTvA/i);
    if (substrPos > -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfTizen(): boolean {
    // return true; // 🚧 DEBUG
    return (window as any).webapis || (window as any).tizen || navigator.userAgent.indexOf('Tizen') !== -1;
  }

  checkIfMovistar(): boolean {
    const existMovistarLib = !!(window as any).laSdk;
    if (existMovistarLib) {
      return true;
    } else {
      return false;
    }
  }

  checkIfWebOS(): boolean {
    const ua = navigator.userAgent;
    const deviceObject: any = document.getElementById('device');
    if (deviceObject && deviceObject?.platform && deviceObject?.platform?.toLowerCase().indexOf('webos') !== -1) {
      return true;
    } else if (ua.indexOf('webOS') !== -1 || ua.indexOf('Web0S') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfNetcast(): boolean {
    const deviceObject: any = document.getElementById('device');
    if (deviceObject && deviceObject?.platform && deviceObject?.platform?.toLowerCase().indexOf('netcast') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfPlatformWithSODevice(): boolean {
    const deviceObject: any = document.getElementById('device');
    if (!!deviceObject['SO_Device']) {
      return true;
    }
    return false;
  }

  detectDeviceType(): AllowedDevicePlatforms {
    // comprobar si es tizen
    if (this.checkIfTizen()) {
      return AllowedDevicePlatformsType.tizen;
    }

    if (this.checkIfIsVizio()) {
      return AllowedDevicePlatformsType.vizio;
    }

    if (this.checkIfMovistar()) {
      return AllowedDevicePlatformsType.movistar;
    }

    if (this.checkIfWebOS()) {
      return AllowedDevicePlatformsType.webos;
    }

    if (this.checkIfNetcast()) {
      return AllowedDevicePlatformsType.netcast;
    }

    if (this.checkIfIsZeans()) {
      return AllowedDevicePlatformsType.zeasn;
    }

    if (this.checkIfPlatformWithSODevice()) {
      const deviceObject: any = document.getElementById('device') || {};
      const platform = deviceObject['SO_Device'];
      return platform || AllowedDevicePlatformsType.basic;
    }
    return AllowedDevicePlatformsType.basic;
  }
}
