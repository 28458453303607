const getParameterByName = ((window as any).getParameterByName = (name, url?) => {
  if (!url && (window as any).location) {
    url = (window as any).location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
});

if (getParameterByName('clearAll')) {
  localStorage.clear();
}

(window as any).useLang = 'es';
