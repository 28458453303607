import { ChangeDetectorRef, Injectable, Renderer2 } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { MetricsService } from '@providers/metrics/metrics.service';

@Injectable()
export class PreviousRouteService {
  isOnTransition = false;
  appLoaded = false;

  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private cdr: ChangeDetectorRef,

    public metrics: MetricsService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  onRouting() {
    console.log('onRouting service');
    this.router.events.subscribe(event => {
      this.navigationInterceptor(event as RouterEvent);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    console.log('navigationInterceptor service');
    if (event instanceof NavigationStart) {
      this.isOnTransition = true;
      this.cdr.detectChanges();
    }
    if (event instanceof NavigationEnd) {
      this.isOnTransition = false;
      this.cdr.detectChanges();
      if (!this.appLoaded) {
        this.appLoaded = true;
        // this.renderer.addClass(document.body.querySelector('.modal-init'), 'app-loaded');
        setTimeout(() => {
          this.renderer.removeChild(document.body, document.body.querySelector('.modal-init'));
        }, 1000);
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isOnTransition = false;
      this.cdr.detectChanges();
    }
    if (event instanceof NavigationError) {
      this.isOnTransition = false;
      this.cdr.detectChanges();
    }
  }
}
