import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { KSHelpers } from '../helpers/ks-helpers';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';

@Injectable()
export class RootScopeAdapter {
  activeFastPlay = false; // Only for testing, do not use in production
  loadedApp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showVideoModal = false;
  appIsInit = false;

  visitCounterWelcomePage = 0;

  messageModalFailPay;

  focusTopTrack: Subject<any> = new Subject();
  loged: Subject<any> = new Subject();
  logout: Subject<any> = new Subject();
  goOutApp: Subject<any> = new Subject();
  launchModal: Subject<any> = new Subject();

  showInGateway;
  sitePlayingModal;

  lastSearchField: string;

  // seccion toptrack y playlist container
  lastSelectedTopTrack;
  lastTopOfTopTrack;
  lastRowSelectIndex = 0;
  // fin seccion toptrack y container

  // seccion search
  lastBeginRow = {};
  lastVisibleRows = {};

  nameStatesObjectComplete = {
    toptrack: 'btnExplora',
    moods: 'ViewModos',
    login: 'btnRegister',
    'login.tv': 'btnRegister',
    'login.sms': 'btnRegister',
    'login.web': 'btnRegister',
    'login.fb': 'btnRegister',
    catalog: 'btnCatalog',
    connect: 'btnConnect',
    search: 'btnSearch',
    user: 'btnUser',
    profile: 'btnUser',
    coupons: 'btnCoupon',
    couponsLg: 'btnCoupon',
    player: 'ViewPlayer',
    subscription: 'btnPayment',
    playlist: 'ViewPlaylist',
    whypremium: 'ViewWhypremium',
    PlayQueue: 'PlayQueue',
  };

  previusState: any = {
    name: 'ks.toptrack',
    params: {},
  };
  nameStatesObject = {
    // 'ks.toptrack'   :'ViewExplora',
    'ks.login': 'btnRegister',
    'ks.catalog': 'btnCatalog',
    'ks.connect': 'btnConnect',
    'ks.search': 'btnSearch',
    'ks.user': 'btnUser',
    // 'ks.player'     :'ViewPlayer',
    'ks.subscription': 'btnPayment',
  };

  url;
  finnishTimer;
  // countdown
  registerOrlogin = 'register';
  lastActionEventPlayer;
  constructor(private localStorage: LocalStorage, private helpers: KSHelpers, private metrics: MetricsService) {}

  sendViewClicked(name, data?) {
    this.metrics.viewClicked(this.nameStatesObjectComplete[name], data);
  }

  visitWelcomePage() {
    this.visitCounterWelcomePage++;
  }
}
