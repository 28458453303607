import { Injectable } from '@angular/core';
import { KsResponse } from '@interfaces/ks-response.interface';
import { Observable, of, throwError } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';

import { IRecordInterface } from '../../interfaces/records.interface';
import { Api } from '../api/api';

@Injectable()
export class RecordsService {
  records: IRecordInterface[] = null;
  recordsPromo: IRecordInterface[] = null;
  constructor(public api: Api) {}

  getRecord(code: string): Observable<IRecordInterface> {
    return this.api.get('record/' + code).pipe(
      switchMap((response: KsResponse<any>) => {
        if (response.success) {
          return of(response.data);
        } else {
          return throwError(response);
        }
      }),
      share()
    );
  }

  getRecords(): Observable<IRecordInterface[]> | Promise<IRecordInterface[]> {
    const promiseList: Promise<IRecordInterface[]> = new Promise((resolve, reject) => {
      if (this.records) {
        resolve(this.records);
      } else {
        this.api.get('record/top').subscribe(
          (res: any) => {
            // console.log(res);
            this.records = res.data;
            resolve(this.records);
          },
          err => {
            alert('Request failed: ' + err);
          }
        );
      }
    });
    return promiseList;
  }

  getRecordsPromo() {
    const recordsPromoPromise = new Promise((resolve, reject) => {
      if (this.recordsPromo) {
        resolve(this.recordsPromo);
      } else {
        this.api.get('record/top/?ispromo=true').subscribe(
          (res: any) => {
            this.recordsPromo = res.data;
            resolve(this.recordsPromo);
          },
          err => {
            alert('Request failed: ' + err);
          }
        );
      }
    });
    return recordsPromoPromise;
  }

  getInfo(record_id: string) {
    return this.api
      .get(`record/${record_id}/info`)
      .pipe(map(res => res.data))
      .toPromise();
  }

  getRecordsByUser(id) {
    return this.api
      .post('record/user', {
        user: id,
      })
      .pipe(
        switchMap(res => {
          if (res.success) {
            // this.records = res.data;
            return of(res.data);
          } else {
            return throwError(res.message);
          }
        })
      );
  }
}
