const kantoKey = 'karaokeSmartKantoLLC';
export const encodeUserID = (id: number): string => {
  const key = kantoKey;
  const stringId = id.toString();
  const ziped = stringId.split('').map((l, i) => [l, key[i % key.length]]);
  // eslint-disable-next-line no-bitwise
  const encoded = btoa(ziped.map(([a, b]) => String.fromCharCode(a.charCodeAt(0) ^ b.charCodeAt(0))).join(''));
  return encoded;
};

export const decodeUserID = (encoded: string): string => {
  const key = kantoKey;

  const data = atob(encoded);

  const ziped = data.split('').map((l, i) => [l, key[i % key.length]]);
  // eslint-disable-next-line no-bitwise
  const decoded = ziped.map(([a, b]) => String.fromCharCode(a.charCodeAt(0) ^ b.charCodeAt(0))).join('');

  return decoded;
};

export const compareUserIDs = (id1: string, id2: string): boolean => {
  if (id1 === undefined || id2 === undefined) {
    return false;
  }
  if (id1 === null || id2 === null) {
    return false;
  }
  if (id1.toString() === id2.toString()) {
    return true;
  } else {
    // check if they are encoded checking if first char is a number
    const firstCharId1 = id1.toString()[0];
    const firstCharId2 = id2.toString()[0];
    const isId1Encoded = isNaN(parseInt(firstCharId1, 10));
    const isId2Encoded = isNaN(parseInt(firstCharId2, 10));

    if (isId1Encoded && !isId2Encoded) {
      return decodeUserID(id1) === id2;
    } else if (!isId1Encoded && isId2Encoded) {
      return id1 === decodeUserID(id2);
    } else {
      return false;
    }
  }
};
