import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { PendingRequestService } from '@providers/pending-request/pending.request.service';
import { find } from 'lodash-es';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, share, switchMap } from 'rxjs/operators';

import { AnonymousSessionInterface, UserSessionResponseInterface } from '../../interfaces/interfaces';
import { Config } from '../config/config';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { RegisterWebService } from '../registerWeb/registerWeb';
import { SessionService } from '../user/session.service';
import { User } from '../user/user';

@Injectable()
export class KSFirebase {
  becomePremiumAction = new Subject<boolean>();
  remoteLoginAction = new Subject<boolean>();
  plans;
  plan;
  fullPlan;
  becomePremiumSubcription: Subscription;
  remoteLoginSubscription: Subscription;
  /**
   *
   * @type currentSession
   */
  currentSession: AngularFireObject<any>;
  /**
   *
   * @type currentToken
   */
  currentToken: AngularFireObject<any>;
  /**
   *
   * @type currentQR
   */
  currentQR: AngularFireObject<any>;
  /**
   *
   * @type currentSessionHost
   */
  currentSessionHost: AngularFireObject<boolean>;
  /**
   *
   * @type currentStartedAt;
   */
  currentSessionStartedAt: AngularFireObject<any>;
  /**
   *
   * @type currentSessionHost
   */
  currentSessionGuest: AngularFireList<any>;

  /**
   *
   * @type currentSessionScore
   */
  currentSessionScore: Observable<any>;
  /**
   *
   */
  currentSessionBecomePremium: Observable<boolean>;

  /**
   * listener pago
   *
   * @function type
   */
  constructor(
    private metrics: MetricsService,
    private db: AngularFireDatabase,
    private location: Location,
    private config: Config,
    private session: SessionService,
    private registerWeb: RegisterWebService,
    private localStorage: LocalStorage,
    private pendingRequestService: PendingRequestService,
    private userService: User
  ) {}

  async getNamePlan(cb) {
    const planId = await this.localStorage.getItem('planPaylinkModal');
    const responseFunct = plans => {
      setTimeout(() => {
        const fullPlan: any = find(plans, { id: parseInt(planId, 10) });
        if (fullPlan) {
          this.plan = fullPlan?.price?.type;
          this.fullPlan = fullPlan;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        cb ? cb() : null;
      }, 3000);
    };
    // eslint-disable-next-line no-eval
    const userId = null;
    if (!userId) {
      this.config
        .getSettings()
        .then(res => {
          responseFunct(res.plans);
        })
        .catch();
    } else {
      this.config
        .getPlans()
        .then(res => {
          responseFunct(res);
        })
        .catch();
    }
  }
  getCurrent(currentUser) {
    return currentUser ? (currentUser.data ? currentUser.data : currentUser) : null;
  }
  init() {
    console.log('KS FIREBASE INIT');
    this.session.session$
      .pipe(
        distinctUntilChanged(
          (previous, current) =>
            previous?.session_id === current?.session_id && previous?.is_anonymous === current?.is_anonymous
        )
      )
      .subscribe((session: AnonymousSessionInterface | UserSessionResponseInterface) => {
        console.log('sesion que llega es', session);
        this.setCurrentQR(this.session.getQR());

        if (this.remoteLoginSubscription) {
          this.remoteLoginSubscription.unsubscribe();
        }

        this.setCurrentSession(this.session.getSessionToken(session));

        if (this.becomePremiumSubcription) {
          this.becomePremiumSubcription.unsubscribe();
          console.log('Ejecuto Unsuscribe');
        }
        if (!this.userService.authenticated || (this.userService.authenticated && !this.userService.premium)) {
          this.becomePremiumSubcription = this.currentSessionBecomePremium
            .pipe(distinctUntilChanged())
            .subscribe(value => {
              if (value) {
                this.becomePremiumAction.next(value);
              }
            });
        }
      });
  }

  getRef(ref) {
    return this.db.database.ref(ref);
  }
  /**
   * Obtiene Sesion de Firebase a partir de token
   *
   * @param session_id
   * @returns
   */
  getSession(session_id): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + session_id);
    return this.db.object(ref);
  }
  /**
   * Obtiene Host de Sesion
   *
   * @param session_id
   * @returns
   */
  getSessionHost(session_id): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + session_id + '/host');
    return this.db.object(ref);
  }

  /**
   * Obtiene startedAt de Sesion
   *  session_id
   *
   * @returns
   */
  getSessionStartedAt(): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + this.currentToken + '/startedAt');
    return this.db.object(ref);
  }

  /**
   * Obtiene la referencia become_premium
   *
   * @returns
   */
  getSessionBecomePremium(): AngularFireObject<boolean> {
    const ref = this.getRef('Sessions/' + this.currentToken + '/become_premium');
    return this.db.object(ref);
  }

  /**
   * Obtiene la referencia become_premium
   *
   * @returns
   */
  getSessionScore(): AngularFireObject<boolean> {
    const ref = this.getRef('Sessions/' + this.currentToken + '/currentPlayingScore/score');
    return this.db.object(ref);
  }

  /**
   * Obtiene listado de celulares
   *  session_id
   *
   */
  getSessionGuest(): AngularFireList<boolean> {
    const ref = this.getRef('Sessions/' + this.currentToken).child('guests');
    return this.db.list(ref);
  }

  /**
   * Obtiene la referencia del plan seleccionado
   * session_id
   *
   * @param plan integer
   * @returns
   */
  getSessionSelectedPlanForPurchase(): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + this.currentToken).child('selectedPlanForPurchase');
    return this.db.object(ref);
  }

  /**
   *
   * @returns
   */
  getPeopleSingingSongs(): AngularFireObject<any> {
    const ref = this.getRef('Songs').child('cant_songs');
    return this.db.object(ref);
  }

  setCurrentQR(qr) {
    this.currentQR = qr;
  }
  /**
   * asigna sesion actual a partir de token
   *
   * @param token
   * @returns
   */
  setCurrentSession(token) {
    this.currentToken = token;
    this.currentSession = this.getSession(token);
    this.currentSessionHost = this.getSessionHost(token);
    this.currentSessionStartedAt = this.getSessionStartedAt();
    this.currentSessionGuest = this.getSessionGuest();
    this.currentSessionScore = this.getSessionScore()
      .valueChanges()
      .pipe(
        switchMap(value => of(value)),
        share()
      );

    this.currentSessionBecomePremium = this.getSessionBecomePremium()
      .valueChanges()
      .pipe(
        filter(() => !this.pendingRequestService.pendingCheckoutRequest),
        distinctUntilChanged(),
        switchMap(value => {
          if (value) {
            // this.userService.updateToPremium();
            const event = () => {
              this.metrics.sendEvent('pasarelaExito', {
                'Where Paid': 'webmobile',
                'Payment Method': this.session.currentRealTimeSession.payment_method,
                'Payment Gateway': this.session.currentRealTimeSession.payment_gateway,
                plan: this.plan,
                'Plan Code': this.fullPlan?.price?.type,
                'Plan Name': this.fullPlan?.name,
                'Plan ID': this.fullPlan?.id,
              });
            };
            this.getNamePlan(event);
          }
          return of(!!value);
        }),
        share()
      );
    this.remoteLoginSubscription = this.currentSessionHost
      .valueChanges()
      .pipe(
        filter(() => !this.pendingRequestService.pendingSignupRequest),
        distinctUntilChanged()
      )
      .subscribe(value => {
        console.log('se ejecuta el host value changes');
        console.log(this.location.path().indexOf('login/app'), 'login/app 2', value);
        if (value) {
          this.registerWeb.initSessionByQR(this.currentQR).then(loginOk => {
            if (loginOk) {
              this.remoteLoginAction.next(loginOk);
              this.remoteLoginSubscription?.unsubscribe();
            }
          });
        }
      });
  }

  getCurrentToken() {
    return this.currentToken;
  }

  getCurrentSession() {
    return this.currentSession;
  }

  getReceiveSmsObject() {
    const ref = this.getRef('Sessions/' + this.currentToken + '/receive_sms');
    return this.db.object(ref);
  }

  getReceiveSmsObservable() {
    const ref = this.getRef('Sessions/' + this.currentToken + '/receive_sms');
    return this.db.object(ref).valueChanges();
  }
}
