import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArtistInterface } from '../../interfaces/artist.initerface';
import { Api } from '../api/api';

@Injectable()
export class ArtistService {
  list = 'search/new_catalog';
  artist = 'search/artists/get_songs';
  constructor(private api: Api) {}

  findAll(...args) {
    const ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return this.api.post(this.list, ctx.query).toPromise();
  }
  songs(...args) {
    const ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return this.api.post(this.artist, ctx.query).toPromise();
  }

  getPlaylist(artistId): Observable<ArtistInterface> {
    return this.api.post('artist/songs', { artist: artistId }).pipe(map(res => res.data));
  }
}
