import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

import { ABTestingService } from '../ab-testing/ab-testing.service';
import { freemiumPeriodState } from '../freemium/freemium-period.states';
import { User } from '../user/user';
import { UserRealtimeService } from '../user/user-realtime';

@Injectable()
export class FreemiumControlService {
  constructor(private user: User, private userRealtime: UserRealtimeService, private abTesting: ABTestingService) {}

  startFreemiumPeriod(): Promise<void> {
    const startFreemiumPeriodAt = firebase.firestore.FieldValue.serverTimestamp();
    const updateDevice: Promise<void> = this.userRealtime.currentDevice.startFreemiumPeriodAt
      ? Promise.resolve(undefined)
      : this.userRealtime.updateRealtimeDeviceData(this.userRealtime.currentDevice, {
          startFreemiumPeriodAt,
        });

    const updateUser = this.userRealtime.updateRealtimeUserData(this.user.currentUser, {
      startFreemiumPeriodAt,
    });

    return Promise.all([updateDevice, updateUser]).then(() => {
      const getDevice = this.userRealtime.getRealTimeDeviceData(this.userRealtime.currentDevice);
      const getUser = this.userRealtime.getRealTimeUserData(this.user.currentUser);
      return Promise.all([getDevice, getUser]).then(([device, user]) => {
        this.userRealtime.currentDevice.startFreemiumPeriodAt = device.startFreemiumPeriodAt;
        this.user.currentUser.startFreemiumPeriodAt = user.startFreemiumPeriodAt;
        return;
      });
    });
  }

  markModalAsOpened(): Promise<void> {
    const updateUser = this.userRealtime.updateRealtimeUserData(this.user.currentUser, {
      freemiumModalOpened: true,
    });
    return updateUser;
  }

  isActiveNewFreemiumForMe() {
    if (!this.user.authenticated) {
      return false;
    }
    if (this.user.premium) {
      return false;
    }

    if (!this.user.premium) {
      if (this.abTesting.iAmCase('newFreemium', 'b')) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  shoudShowStartModal() {
    if (this.isActiveNewFreemiumForMe()) {
      if (this.iHasStartedFreemiumPeriodOnDevice()) {
        return false;
      }
      if (this.iHasStartedFreemiumPeriod()) {
        return false;
      }
      return true;
    }
    return false;
  }

  iHasStartedFreemiumPeriod(): boolean {
    return !!this.user.currentUser.startFreemiumPeriodAt;
  }

  iHasStartedFreemiumPeriodOnDevice(): boolean {
    return !!this.userRealtime.currentDevice.startFreemiumPeriodAt;
  }

  getTimeToStartFreemiumPeriod() {
    if (!this.userRealtime.currentDevice.startFreemiumPeriodAt) {
      return this.user.currentUser.startFreemiumPeriodAt['seconds'];
    }
    return Math.min(
      this.userRealtime.currentDevice.startFreemiumPeriodAt['seconds'],
      this.user.currentUser.startFreemiumPeriodAt['seconds']
    );
  }

  getLimitFreemiumPeriod() {
    const timePremium = 30 * 60 * 1000; // tiempo freemium 30 min
    const startTime = this.getTimeToStartFreemiumPeriod() * 1000;
    return startTime + timePremium;
  }

  getFreemiumTimeRemaining() {
    const now = new Date().getTime();
    const timeRemaining = this.getLimitFreemiumPeriod() - now;
    return timeRemaining;
  }

  iAmOnFreemiumPeriod(): boolean {
    if (!this.iHasStartedFreemiumPeriod()) {
      return false;
    }
    const timePremium = 30 * 60 * 1000; // tiempo freemium 30 min
    const now = new Date().getTime();
    const startTime = this.getTimeToStartFreemiumPeriod() * 1000;
    const timeElapsed = now - startTime;
    return now > startTime && timeElapsed <= timePremium;
  }

  getFreemiumPeriodState(): freemiumPeriodState {
    if (!this.iHasStartedFreemiumPeriod()) {
      return freemiumPeriodState.NOT_STARTED;
    } else if (this.iAmOnFreemiumPeriod()) {
      return freemiumPeriodState.STARTED;
    } else {
      return freemiumPeriodState.ENDED;
    }
  }

  periodIsNotStarted(): boolean {
    return this.getFreemiumPeriodState() === freemiumPeriodState.NOT_STARTED;
  }

  periodIsStarted(): boolean {
    return this.getFreemiumPeriodState() === freemiumPeriodState.STARTED;
  }

  periodIsEnded(): boolean {
    return this.getFreemiumPeriodState() === freemiumPeriodState.ENDED;
  }
}
