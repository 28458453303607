import { CookieOptionsArgs } from '../cookies/cookie-options-args.model';

/** @private */
export class CookieOptions {
  path: string;
  domain: string;
  expires: string | Date;
  secure: boolean;

  constructor({ path, domain, expires, secure }: CookieOptionsArgs = {}) {
    this.path = this.isPresent(path) ? path : null;
    this.domain = this.isPresent(domain) ? domain : null;
    this.expires = this.isPresent(expires) ? expires : null;
    this.secure = this.isPresent(secure) ? secure : false;
  }

  merge(options?: CookieOptionsArgs): CookieOptions {
    return new CookieOptions({
      path: this.isPresent(options) && this.isPresent(options.path) ? options.path : this.path,
      domain: this.isPresent(options) && this.isPresent(options.domain) ? options.domain : this.domain,
      expires: this.isPresent(options) && this.isPresent(options.expires) ? options.expires : this.expires,
      secure: this.isPresent(options) && this.isPresent(options.secure) ? options.secure : this.secure,
    } as CookieOptionsArgs);
  }

  private isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }
}
