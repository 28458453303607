export const keyCodes = [
  { type: 'short', key: 'Backspace', keycode: 8, code: 'Backspace', shiftKey: false },
  { type: 'short', key: 'Enter', keycode: 13, code: 'Enter', shiftKey: false },
  { type: 'short', key: 'MediaPause', keycode: 19, code: 'MediaPause', shiftKey: false },
  { type: 'short', key: 'Exit', keycode: 27, code: 'Exit', shiftKey: false },
  { type: 'short', key: 'Space', keycode: 32, code: 'Space', shiftKey: false },
  { type: 'short', key: 'PageUp', keycode: 33, code: 'PageUp', shiftKey: false },
  { type: 'short', key: 'PageDown', keycode: 34, code: 'PageDown', shiftKey: false },
  { type: 'short', key: 'ArrowLeft', keycode: 37, code: 'ArrowLeft', shiftKey: false },
  { type: 'short', key: 'ArrowUp', keycode: 38, code: 'ArrowUp', shiftKey: false },
  { type: 'short', key: 'ArrowRight', keycode: 39, code: 'ArrowRight', shiftKey: false },
  { type: 'short', key: 'ArrowDown', keycode: 40, code: 'ArrowDown', shiftKey: false },
  { type: 'short', key: 'MediaRewind', keycode: 412, code: 'MediaRewind', shiftKey: false },
  { type: 'short', key: 'MediaStop', keycode: 413, code: 'MediaStop', shiftKey: false },
  { type: 'short', key: 'MediaPlay', keycode: 415, code: 'MediaPlay', shiftKey: false },
  { type: 'short', key: 'MediaFastForward', keycode: 417, code: 'MediaFastForward', shiftKey: false },
  { type: 'short', key: 'MediaTrackNext', keycode: 418, code: 'MediaTrackNext', shiftKey: false },
  { type: 'short', key: 'MediaTrackPrevious', keycode: 419, code: 'MediaTrackPrevious', shiftKey: false },
  { type: 'short', key: 'PrevCh', keycode: 500, code: 'PrevCh', shiftKey: false },
  { type: 'alpha', key: '0', keycode: 48, code: 'Digit0', shiftKey: false },
  { type: 'alpha', key: '1', keycode: 49, code: 'Digit1', shiftKey: false },
  { type: 'alpha', key: '2', keycode: 50, code: 'Digit2', shiftKey: false },
  { type: 'alpha', key: '3', keycode: 51, code: 'Digit3', shiftKey: false },
  { type: 'alpha', key: '4', keycode: 52, code: 'Digit4', shiftKey: false },
  { type: 'alpha', key: '5', keycode: 53, code: 'Digit5', shiftKey: false },
  { type: 'alpha', key: '6', keycode: 54, code: 'Digit6', shiftKey: false },
  { type: 'alpha', key: '7', keycode: 55, code: 'Digit7', shiftKey: false },
  { type: 'alpha', key: '8', keycode: 56, code: 'Digit8', shiftKey: false },
  { type: 'alpha', key: '9', keycode: 57, code: 'Digit9', shiftKey: false },
  { type: 'alpha', key: 'A', keycode: 65, code: 'KeyA', shiftKey: true },
  { type: 'alpha', key: 'a', keycode: 65, code: 'KeyA', shiftKey: false },
  { type: 'alpha', key: 'B', keycode: 66, code: 'KeyB', shiftKey: true },
  { type: 'alpha', key: 'b', keycode: 66, code: 'KeyB', shiftKey: false },
  { type: 'alpha', key: 'C', keycode: 67, code: 'KeyC', shiftKey: true },
  { type: 'alpha', key: 'c', keycode: 67, code: 'KeyC', shiftKey: false },
  { type: 'alpha', key: 'D', keycode: 68, code: 'KeyD', shiftKey: true },
  { type: 'alpha', key: 'd', keycode: 68, code: 'KeyD', shiftKey: false },
  { type: 'alpha', key: 'E', keycode: 69, code: 'KeyE', shiftKey: true },
  { type: 'alpha', key: 'e', keycode: 69, code: 'KeyE', shiftKey: false },
  { type: 'alpha', key: 'F', keycode: 70, code: 'KeyF', shiftKey: true },
  { type: 'alpha', key: 'f', keycode: 70, code: 'KeyF', shiftKey: false },
  { type: 'alpha', key: 'G', keycode: 71, code: 'KeyG', shiftKey: true },
  { type: 'alpha', key: 'g', keycode: 71, code: 'KeyG', shiftKey: false },
  { type: 'alpha', key: 'H', keycode: 72, code: 'KeyH', shiftKey: true },
  { type: 'alpha', key: 'h', keycode: 72, code: 'KeyH', shiftKey: false },
  { type: 'alpha', key: 'I', keycode: 73, code: 'KeyI', shiftKey: true },
  { type: 'alpha', key: 'i', keycode: 73, code: 'KeyI', shiftKey: false },
  { type: 'alpha', key: 'J', keycode: 74, code: 'KeyJ', shiftKey: true },
  { type: 'alpha', key: 'j', keycode: 74, code: 'KeyJ', shiftKey: false },
  { type: 'alpha', key: 'K', keycode: 75, code: 'KeyK', shiftKey: true },
  { type: 'alpha', key: 'k', keycode: 75, code: 'KeyK', shiftKey: false },
  { type: 'alpha', key: 'L', keycode: 76, code: 'KeyL', shiftKey: true },
  { type: 'alpha', key: 'l', keycode: 76, code: 'KeyL', shiftKey: false },
  { type: 'alpha', key: 'M', keycode: 77, code: 'KeyM', shiftKey: true },
  { type: 'alpha', key: 'm', keycode: 77, code: 'KeyM', shiftKey: false },
  { type: 'alpha', key: 'N', keycode: 78, code: 'KeyN', shiftKey: true },
  { type: 'alpha', key: 'n', keycode: 78, code: 'KeyN', shiftKey: false },
  { type: 'alpha', key: 'O', keycode: 79, code: 'KeyO', shiftKey: true },
  { type: 'alpha', key: 'o', keycode: 79, code: 'KeyO', shiftKey: false },
  { type: 'alpha', key: 'P', keycode: 80, code: 'KeyP', shiftKey: true },
  { type: 'alpha', key: 'p', keycode: 80, code: 'KeyP', shiftKey: false },
  { type: 'alpha', key: 'Q', keycode: 81, code: 'KeyQ', shiftKey: true },
  { type: 'alpha', key: 'q', keycode: 81, code: 'KeyQ', shiftKey: false },
  { type: 'alpha', key: 'R', keycode: 82, code: 'KeyR', shiftKey: true },
  { type: 'alpha', key: 'r', keycode: 82, code: 'KeyR', shiftKey: false },
  { type: 'alpha', key: 'S', keycode: 83, code: 'KeyS', shiftKey: true },
  { type: 'alpha', key: 's', keycode: 83, code: 'KeyS', shiftKey: false },
  { type: 'alpha', key: 'T', keycode: 84, code: 'KeyT', shiftKey: true },
  { type: 'alpha', key: 't', keycode: 84, code: 'KeyT', shiftKey: false },
  { type: 'alpha', key: 'U', keycode: 85, code: 'KeyU', shiftKey: true },
  { type: 'alpha', key: 'u', keycode: 85, code: 'KeyU', shiftKey: false },
  { type: 'alpha', key: 'V', keycode: 86, code: 'KeyV', shiftKey: true },
  { type: 'alpha', key: 'v', keycode: 86, code: 'KeyV', shiftKey: false },
  { type: 'alpha', key: 'W', keycode: 87, code: 'KeyW', shiftKey: true },
  { type: 'alpha', key: 'w', keycode: 87, code: 'KeyW', shiftKey: false },
  { type: 'alpha', key: 'X', keycode: 88, code: 'KeyX', shiftKey: true },
  { type: 'alpha', key: 'x', keycode: 88, code: 'KeyX', shiftKey: false },
  { type: 'alpha', key: 'Y', keycode: 89, code: 'KeyY', shiftKey: true },
  { type: 'alpha', key: 'y', keycode: 89, code: 'KeyY', shiftKey: false },
  { type: 'alpha', key: 'Z', keycode: 90, code: 'KeyZ', shiftKey: true },
  { type: 'alpha', key: 'z', keycode: 90, code: 'KeyZ', shiftKey: false },
  { type: 'special', key: ',', keycode: 44, code: 'Comma', shiftKey: false },
  { type: 'special', key: '-', keycode: 45, code: 'Minus', shiftKey: false },
  { type: 'special', key: '.', keycode: 46, code: 'Period', shiftKey: false },
  { type: 'special', key: '\\', keycode: 47, code: 'Slash', shiftKey: false },
  { type: 'special', key: ':', keycode: 58, code: 'Semicolon', shiftKey: true },
  { type: 'special', key: ';', keycode: 59, code: 'Semicolon', shiftKey: false },
  { type: 'special', key: '<', keycode: 60, code: 'Comma', shiftKey: true },
  { type: 'special', key: '=', keycode: 61, code: 'Equal', shiftKey: false },
  { type: 'special', key: '>', keycode: 62, code: 'Period', shiftKey: true },
  { type: 'special', key: '?', keycode: 63, code: 'Slash', shiftKey: true },
  { type: 'special', key: '@', keycode: 64, code: 'Digit2', shiftKey: true },
  { type: 'special', key: '/', keycode: 92, code: 'Slash', shiftKey: false },
  { type: 'special', key: '^', keycode: 160, code: 'Digit6', shiftKey: true },
  { type: 'special', key: '!', keycode: 161, code: 'Digit1', shiftKey: true },
  { type: 'special', key: '"', keycode: 162, code: 'Quote', shiftKey: true },
  { type: 'special', key: '#', keycode: 163, code: 'Digit3', shiftKey: true },
  { type: 'special', key: '$', keycode: 164, code: 'Digit4', shiftKey: true },
  { type: 'special', key: '%', keycode: 165, code: 'Digit5', shiftKey: true },
  { type: 'special', key: '&', keycode: 166, code: 'Digit7', shiftKey: true },
  { type: 'special', key: '_', keycode: 167, code: 'Minus', shiftKey: true },
  { type: 'special', key: '(', keycode: 168, code: 'Digit9', shiftKey: true },
  { type: 'special', key: ')', keycode: 169, code: 'Digit0', shiftKey: true },
  { type: 'special', key: '*', keycode: 170, code: 'Digit8', shiftKey: true },
  { type: 'special', key: '+', keycode: 171, code: 'Equal', shiftKey: true },
  { type: 'special', key: '{', keycode: 174, code: 'BracketLeft', shiftKey: true },
  { type: 'special', key: '}', keycode: 175, code: 'BracketRight', shiftKey: true },
  { type: 'special', key: '~', keycode: 176, code: 'Backquote', shiftKey: true },
  { type: 'special', key: '[', keycode: 219, code: 'BracketLeft', shiftKey: false },
  { type: 'special', key: 'I', keycode: 220, code: 'Slash', shiftKey: true },
  { type: 'special', key: ']', keycode: 221, code: 'BracketRight', shiftKey: false },
  { type: 'special', key: "'", keycode: 222, code: 'Quote', shiftKey: false },
  { type: 'special', key: ' ', keycode: 32, code: 'Space', shiftKey: false },
];
