import { Injectable } from '@angular/core';
import { IMedia } from '@interfaces/interfaces';
import { KsResponse } from '@interfaces/ks-response.interface';
import { Observable, of, throwError } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';

import { Api } from '../api/api';

@Injectable()
export class SongsService {
  constructor(private api: Api) {}

  catalog(data): Promise<any> {
    return this.api.post('search/new_catalog', data).toPromise();
  }

  findAll(ctx) {
    ctx = ctx || { where: { cat: null } };
    // this.topTrack.get(ctx);
  }

  findByTag(tag: string): Observable<IMedia> {
    return this.api.post('song/tag', { tag }).pipe(
      switchMap((response: KsResponse<IMedia>) => {
        if (response.success) {
          return of(response.data);
        } else {
          return throwError(response);
        }
      }),
      share()
    );
  }
}
