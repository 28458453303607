import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Config } from '@providers/config/config';
import { CountryService } from '@providers/country/country';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentChange,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { collectionFromOnSnapshot, mapCollection, mapFilteredDocChanges } from '@providers/helpers/firestore';
import { compareUserIDs } from '@providers/user/user-id-encoder.helper';
import { findIndex } from 'lodash-es';
// import { filter as _filter } from 'lodash-es';
import { BehaviorSubject, Observable, pipe, Subject, Subscription } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import { Sticker } from '../../models';
import { getPictureUrlFromProfile } from '../helpers/helpers';
import { MetricsService } from '../metrics/metrics.service';
import { Notifications } from '../notifications/notifications';
import { SessionService } from '../user/session.service';
import { User } from '../user/user';
import { IFriend } from './friend.interface';

@Injectable()
export class FriendsService {
  friendsCollection: CollectionReference<IFriend>;
  stickersCollection: CollectionReference<Sticker>;
  friends: Observable<IFriend[]>;
  connectedUsers: IFriend[] = [];
  friendsSub: Subscription;
  stickersReceiver: Observable<Sticker>;
  readyStickers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  firebaseStickersList: AngularFireList<Sticker>;
  fbImg;
  userFullName;
  userDisconect: Subject<any> = new Subject();

  deviceNotAvailableForScore: Subject<any> = new Subject();
  get connectedFriends() {
    return this.connectedUserFriends.length;
  }
  get currentUser() {
    return this.user.currentUser;
  }

  get connectedUserFriends(): IFriend[] {
    // return _filter(this.connectedUsers, friend => friend.state !== 'offline');
    return this.connectedUsers;
  }

  get connectedUserFriendsAvailablesForScore(): IFriend[] {
    return this.connectedUsers.filter(friend => friend.isAvailableForScore === true);
  }

  supportedVersion: {
    android: string;
    ios: string;
  } = {
    android: '6.5.7',
    ios: '4.18.0',
  };

  constructor(
    public db: AngularFireDatabase,
    private metrics: MetricsService,
    public notifications: Notifications,
    private session: SessionService,
    private user: User,
    private country: CountryService,
    private config: Config,
    private router: Router
  ) {
    this.session.session$.subscribe(sess => {
      if (!sess) {
        return;
      }
      this.friendsCollection = collection(this.session.sessionDoc, 'users') as CollectionReference<IFriend>;
      this.stickersCollection = collection(this.session.sessionDoc, 'stickers') as CollectionReference<Sticker>;
      this.firebaseStickersList = this.db.list(`Sessions/${this.session.getSessionToken(session)}/stickers`);
      this.friends = collectionFromOnSnapshot(this.friendsCollection, true)
        .pipe(mapFilteredDocChanges(['added', 'modified', 'removed']))

        .pipe(map(mapCollection(this.checkFriend.bind(this))))
        .pipe(map(friends => friends))

        .pipe(
          //map(this.listenFriendEventsFunction()),
          share()
        );
      this.activeListenFriends();
    });
  }

  activeListenFriends() {
    if (this.friendsSub) {
      this.friendsSub.unsubscribe();
    }
    this.friendsSub = this.friends.subscribe(() => {});
    this.stickersReceiver = collectionFromOnSnapshot(this.stickersCollection, true)
      .pipe(mapFilteredDocChanges(['added']))
      .pipe(
        map(this.mapStickersFunction('SYSTEM')),
        filter((friendStickers: Sticker[]) => friendStickers.length > 0),
        map((friendStickers: Sticker[]) => {
          const sticker = new Sticker(friendStickers[0]);
          sticker.setRamdomPosition();
          return sticker;
        })
      );

    this.readyStickers.next(true);
  }

  activeStickerFriend() {}

  settingDataForEvent(data: any) {
    return {
      Guest_deleted: data.deleted,
      Guest_email: data.email,
      Guest_fb_id: data.fb_id,
      Guest_id: data.id,
      Guest_img: data.img,
      Guest_name: data.name,
      Guest_premium: data.premium,
    };
  }

  isEqualOrGreaterVersion(version: string, os: 'android' | 'ios') {
    // check if valid version format
    if (!version.match(/^\d+\.\d+\.\d+$/)) {
      return false;
    }
    const versionArray = version.split('.');
    const suuportedVersion = this.supportedVersion[os] || this.supportedVersion.android;
    const supportedVersionArray = suuportedVersion.split('.');

    for (let i = 0; i < versionArray.length; i++) {
      if (parseInt(versionArray[i], 10) > parseInt(supportedVersionArray[i], 10)) {
        return true;
      }
      if (parseInt(versionArray[i], 10) < parseInt(supportedVersionArray[i], 10)) {
        return false;
      }
    }
    return true;
  }

  checkFriend(documentChange: DocumentChange<IFriend>, friend: IFriend) {
    if (documentChange.type === 'added') {
      // TODO: check when this event is triggered twice when the same id is added
      this.metrics.sendEvent('ConnectionToTV', this.settingDataForEvent(friend));
      if (!friend.added) {
        let isSessionOwner = false;
        if (this.currentUser) {
          this.userFullName = this.currentUser.first_name + ' ' + this.currentUser.last_name;
          this.notifications.notify('addFriendList', {
            name: friend.name,
            userImg: getPictureUrlFromProfile(friend),
            tvOwner: this.userFullName,
          });
          if (compareUserIDs(friend.id, this.currentUser.id.toString())) {
            isSessionOwner = true;
          }
        }
        let isAvailableForScore = false;
        if (friend.version) {
          isAvailableForScore = this.isEqualOrGreaterVersion(friend.version, friend.os || 'android');
        }
        if (!isAvailableForScore) {
          this.deviceNotAvailableForScore.next(friend);
        }
        const friendDoc = doc(this.friendsCollection, friend.id);
        updateDoc(friendDoc, { added: true, isSessionOwner, isAvailableForScore });
      }
      const index = findIndex(this.connectedUsers, { id: friend.id });
      if (index === -1) {
        this.connectedUsers.push(friend);
      } else {
        this.connectedUsers[index] = {
          ...this.connectedUsers[index],
          ...friend,
        };
      }
      // TODO: refactor this, do not use router here
      if (
        this.currentUser &&
        !this.currentUser.premium &&
        this.config.countryIsNotFreemium(this.country.currentCountry)
      ) {
        this.router.navigate(['subscription']);
      }
    } else if (documentChange.type === 'modified') {
      const userData = documentChange.doc.data();
      userData.id = userData.id.toString();
      const friendIndex = this.connectedUsers.findIndex(elem => elem.id === userData.id);
      this.connectedUsers[friendIndex] = userData;
      if (userData.state && userData.state === 'offline') {
        this.userDisconect.next(userData);
      }
    } else if (documentChange.type === 'removed') {
      this.connectedUsers.splice(
        this.connectedUsers.findIndex(user => user.id === friend.id),
        1
      );
    }
  }

  mapStickersFunction(friendId: string): (actions: DocumentChange<Sticker>[]) => Sticker[] {
    return mapCollection((docChange, data) => {
      data.owner = friendId;
    });
  }

  deleteSticker(friend_id: string, sticker_id: string) {
    deleteDoc(doc(this.stickersCollection, sticker_id));
  }

  onReadyStickers(callBack: any) {
    return this.readyStickers.subscribe(ready => {
      if (ready) {
        callBack();
      }
    });
  }
}
