import { Injectable } from '@angular/core';

import { Config } from '../config/config';
import { CountryService } from '../country/country';

@Injectable()
export class UserPlaylistService {
  IdPlaylistFavorite = 90;

  constructor(private country: CountryService, public config: Config) {
    setTimeout(() => {
      this.setIdPlaylistFavorite();
      // console.log(this.IdPlaylistFavorite, 'this.IdPlaylistFavorite');
    }, 500);
  }

  setIdPlaylistFavorite() {
    // [90, 709, 710]
    this.country.onReady(res => {
      if (this.country.currentCountry === 'BR') {
        this.IdPlaylistFavorite = 709;
      }
      if (this.country.currentCountry && this.config.countrysArray.indexOf(this.country.currentCountry) === -1) {
        this.IdPlaylistFavorite = 710;
      }
    });
  }
}
