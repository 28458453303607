import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CookiesService } from '@providers/cookies/cookies.service';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { localStorageFactory } from '@providers/local-storage/local-storage.factory';
import { Platform } from '@providers/platform/platform';

import { DeviceDetectorService } from '../device-ks/device-detector.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [{ provide: APP_INITIALIZER, useValue: () => LocalStorageModule._loading, multi: true }],
})
export class LocalStorageModule {
  private static _loading: Promise<boolean>;

  constructor(deviceDetector: DeviceDetectorService, localStorageService: LocalStorage) {
    LocalStorageModule._loading = new Promise(resolve => {
      if (deviceDetector.checkIfMovistar()) {
        window.laSdk.ready().then(() => {
          localStorageService.$ready.next(true);
          resolve(true);
        });
      } else {
        localStorageService.$ready.next(true);
        resolve(true);
      }
    });
  }
  static detect(): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [
        {
          provide: LocalStorage,
          useFactory: localStorageFactory,
          deps: [Platform, CookiesService, DeviceDetectorService],
        },
      ],
    };
  }
}
