import { Route } from '@angular/router';

export const masterState: Route = {
  path: '',
  // loadChildren: () => import('./pages/toptrack/toptrack.module').then(m => m.ToptrackModule),
  loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
  // redirectTo: 'toptrack',
  // pathMatch: 'ull',
  // resolve: {f
  //   country: CountryResolver,
  //   currentUser: CurrentUserResolver
  // }
};

export const toptrackState: Route = {
  path: 'toptrack',
  redirectTo: '',
};
export const lgproduccionState: Route = {
  path: 'lgproduccion',
  redirectTo: '',
};

export const index2State: Route = {
  path: 'index2',
  redirectTo: '',
};

export const indexHTMLState: Route = {
  path: 'index.html',
  redirectTo: '',
};

export const waitMobile: Route = {
  path: 'wait-mobile',
  loadChildren: () => import('./pages/wait-mobile/wait-mobile.module').then(m => m.WaitMobileModule),
};

export const thanksForPay: Route = {
  path: 'thanks-for-pay',
  loadChildren: () => import('./pages/thanks-for-pay/thanks-for-pay.module').then(m => m.ThanksForPayModule),
};

export const premiumOnboarding: Route = {
  path: 'premium-onboarding',
  loadChildren: () =>
    import('./pages/premium-onboarding/premium-onboarding.module').then(m => m.PremiumOnboardingModule),
};

// export const kantoNotAvailable: Route = {
//   path: 'kanto-not-available',
//   loadChildren: () => import('./pages/kanto-not-available/kanto-not-available.module').then(m => m.KantoNotAvailableModule),
// };

export const userState: Route = {
  path: 'user',
  loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
};

export const smsWizardState: Route = {
  path: 'sms-wizard',
  loadChildren: () => import('./pages/sms-wizard/sms-wizard.module').then(m => m.SmsWizardModule),
};

export const castState: Route = {
  path: 'cast',
  loadChildren: () => import('./pages/cast/cast.module').then(m => m.CastModule),
};

export const appRoutes: Route[] = [
  masterState,
  // {
  //   path: 'safetypay',
  //   loadChildren: () => import('./pages/safety-pay-modal/safety-pay-modal.module').then(m => m.SafetyPayModalModule),
  // },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./pages/subscription-score/subscription-score.module').then(m => m.SubscriptionScoreModule),
  },
  {
    path: 'waiting-web-checkout',
    loadChildren: () =>
      import('./pages/waiting-web-checkout/waiting-web-checkout.module').then(m => m.WaitingWebCheckoutModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
  },
  {
    path: 'coupons',
    loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponModule),
  },
  lgproduccionState,
  index2State,
  indexHTMLState,
  toptrackState,
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'welcome-video',
    loadChildren: () => import('./pages/video-layout/video-layout.module').then(m => m.VideoLayoutModule),
  },
  {
    path: 'welcome-channel',
    loadChildren: () => import('./pages/welcome-channel/welcome-channel.module').then(m => m.WelcomeChannelModule),
  },
  {
    path: 'movistar-home',
    loadChildren: () => import('./pages/movistar-home/movistar-home.module').then(m => m.MovistarHomeModule),
  },
  {
    path: 'kanto-not-available',
    loadChildren: () =>
      import('./pages/kanto-not-available/kanto-not-available.module').then(m => m.KantoNotAvailableModule),
  },
  // {
  //   path: 'welcome-image',
  //   loadChildren: () => import('./pages/welcome-image/welcome-image.module').then(m => m.WelcomeImageModule),
  // },
  castState,
  waitMobile,
  thanksForPay,
  premiumOnboarding,
];
