export enum KeyActions {
  None = 'none',
  Selected = 'selected',
  Skip = 'skip',
}

export interface IKeyAction {
  status: KeyActions;
}

export interface IActionMapping {
  enterZone?: (selectedItem?: any, newSelected?: any) => void;
  leaveZone?: (selectedItem?: any, newSelected?: any) => void;
  moveSelected?: (selectedItem?: any, newSelected?: any) => void;
}

export type IKeyFunction = (selectedItem?: any, newSelected?: any) => IKeyAction | IKeyFunction;

export interface IKeyMapping {
  [key: number]: IKeyFunction;
}

export const defaultActionFunction: IKeyFunction = (selectedItem?: any, newSelected?: any) => ({
  status: KeyActions.None,
});
