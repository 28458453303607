import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'smart-router-animation-wrap',
  templateUrl: './smart-router-animation-wrap.component.html',
  styleUrls: ['./smart-router-animation-wrap.component.scss'],
})
export class SmartRouterAnimationWrapComponent implements OnInit {
  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',
    margin: '0 auto',
  };

  constructor() {}

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }

  ngOnInit() {}
}
