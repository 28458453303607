import { Injectable } from '@angular/core';
import { RemoteContactDataInterface } from '@interfaces/remote-contact-data.interface';
import { Api, CountryService } from '@providers/providers';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteContactDataService {
  contactData$: BehaviorSubject<RemoteContactDataInterface | undefined> = new BehaviorSubject<RemoteContactDataInterface | undefined>(
    undefined
  );

  prevCurrentCountry: string;

  get currentContactData(): RemoteContactDataInterface | undefined {
    return this.contactData$.value ? this.contactData$.value : undefined;
  }
  constructor(private api: Api, private country: CountryService) {
    this.prevCurrentCountry = this.country.currentCountry;
   }


  getRemoteContactData() {
    this.callApiGetContactData();
    this.onChangeCountry();
  }

  callApiGetContactData() {
    this.api.get('customer_services').subscribe(
      res => {
        this.contactData$.next(res.data as RemoteContactDataInterface);
      },
      error => {
        console.log('error getting contact data ', error);
      }
    );
  }

  onChangeCountry() {
    this.country.country.subscribe(
      res => {
        if (res !== this.prevCurrentCountry) {
          this.prevCurrentCountry = res;
          this.callApiGetContactData();
        }
      }
    );
  }
}
