import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PendingRequestService {
  pendingRequest$ = new BehaviorSubject(false);
  pendingSignupRequest$ = new BehaviorSubject(false);
  pendingCheckoutRequest$ = new BehaviorSubject(false);

  get pendingRequest() {
    return this.pendingRequest$.value;
  }

  get pendingSignupRequest() {
    return this.pendingSignupRequest$.value;
  }

  get pendingCheckoutRequest() {
    return this.pendingCheckoutRequest$.value;
  }

  constructor() {}
}
