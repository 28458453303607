import { AllowedDevicePlatforms, AllowedDevicePlatformsType } from '@providers/device-ks/device-platform.interface';
import { IMedia } from 'src/interfaces/interfaces';

export const getSourceAndTone = (
  song: IMedia,
  tone?: string,
  voice?: boolean,
  type: 'hls' | 'mp4' = 'hls', // Default to hls
  platform?: AllowedDevicePlatforms
) => {
  let videoUrl = '';
  // Simple Logic to select the type and adjust according to the platform
  const isMovistarPlatform = platform === AllowedDevicePlatformsType.movistar;
  const selectedType = isMovistarPlatform || type === 'mp4' ? 'mp4' : 'hls';
  let hasBeenForcedToMp4 = false;

  // Select the video URL according to the selected type
  if (selectedType === 'hls') {
    videoUrl = song.hls && song.hls['0'] ? song.hls['0'].replace('http://', 'https://') : ''; // Asegura HTTPS
    if (!videoUrl) {
      hasBeenForcedToMp4 = true;
      videoUrl = song.video;
    }
  } else {
    // 'mp4'
    videoUrl = song.video;
  }

  // Tone and Voice logic
  if (tone && parseInt(tone, 10) !== 0 && (song.tones || song.hls)) {
    let sourceOfTones = selectedType === 'hls' ? song.hls : song.tones;
    if (selectedType === 'hls' && (!sourceOfTones || !sourceOfTones[tone])) {
      hasBeenForcedToMp4 = true;
      sourceOfTones = song.tones;
    }

    const toneUrl = sourceOfTones[tone];
    videoUrl = toneUrl ? toneUrl.replace('http://', 'https://') : videoUrl; // Asegura HTTPS también aquí
  } else if (voice) {
    videoUrl = song.video_voice || videoUrl; // Usa la voz si está disponible
  }

  // Construcción del objeto de retorno
  return [
    {
      type: hasBeenForcedToMp4 ? 'video/mp4' : selectedType === 'hls' ? 'application/x-mpegurl' : 'video/mp4',
      src: videoUrl,
    },
  ];
};

export const getMP4SourceAndTone = (song: IMedia, tone?: string, voice?: boolean) =>
  getSourceAndTone(song, tone, voice, 'mp4');

export const canPlayThisSong = (song: IMedia) => true;
