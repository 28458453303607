import 'firebase/database';
import 'firebase/firestore';

import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';

// import { getFirestore, provideFirestore } from '@providers/firebase/firestore.functions';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideFirestore(() => getFirestore()),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class SharedModule {}
