import { Injectable } from '@angular/core';
import { IDevice } from '@interfaces/device.interface';
import { UserRealTimeInterface, UserSessionResponseInterface } from '@interfaces/interfaces';
import {
  CollectionReference,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  mainCollection,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { validateSnapshot } from '@providers/helpers/firestore';
import { omit, pick } from 'lodash-es';
import { BehaviorSubject, from } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import { DeviceKS } from '../device-ks/device-ks';

@Injectable()
export class UserRealtimeService {
  currentDevice: IDevice;

  public currentRealtimeUser$: BehaviorSubject<UserRealTimeInterface | null> =
    new BehaviorSubject<UserRealTimeInterface | null>(null);

  private usersCollectionRoute = 'users';
  private devicesCollectionRoute = 'devices';

  private usersCollection: CollectionReference<UserRealTimeInterface> = mainCollection(
    this.firestore,
    this.usersCollectionRoute
  ) as CollectionReference<UserRealTimeInterface>;

  private devicesCollection: CollectionReference<IDevice> = mainCollection(
    this.firestore,
    this.devicesCollectionRoute
  ) as CollectionReference<IDevice>;

  private running$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private runningUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private firestore: Firestore, private deviceKS: DeviceKS) {}

  cleanUserData(user: UserSessionResponseInterface): UserRealTimeInterface {
    const selectedData = pick(user, ['id', 'country', 'premium', 'user_name']);
    return omit(selectedData, ['email', 'token', 'plans', 'is_verify', '']) as UserRealTimeInterface;
  }

  getRamdomTime(min, max): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async initRealTimeDevice(): Promise<IDevice> {
    const device: IDevice = {
      duid: this.deviceKS.currentDUID,
      platform: {
        name: this.deviceKS.devicePlatform.name,
        type: this.deviceKS.devicePlatform.type,
      },
    };

    if (!this.running$.value) {
      this.running$.next(true);
      if (!!this.currentDevice) {
        return this.currentDevice;
      }
      // register device

      const deviceDocRef: DocumentReference<IDevice> = doc<IDevice>(this.devicesCollection, device.duid.toString());
      await runTransaction(this.firestore, transaction =>
        transaction.get(deviceDocRef).then(uDoc => {
          if (!uDoc.exists) {
            device.firstTimeCounterAt = serverTimestamp();
            device.customFirstTimeDifferenceCounter = this.getRamdomTime(1, 3600);
          } else {
            const deviceData: IDevice = uDoc.data();
            if (!deviceData.firstTimeCounterAt) {
              device.firstTimeCounterAt = serverTimestamp();
              device.customFirstTimeDifferenceCounter = this.getRamdomTime(1, 3600);
            }
          }
          device.lastLogin = serverTimestamp();
          transaction.set(deviceDocRef, device, { merge: true });
        })
      );

      this.running$.next(false);
      return this.getRealTimeDeviceData(device);
    } else {
      return this.running$
        .pipe(
          take(1),
          switchMap(() => this.getRealTimeDeviceData(device))
        )
        .toPromise();
    }
  }

  async initRealTimeUser(user: UserSessionResponseInterface): Promise<UserRealTimeInterface> {
    if (!this.runningUser$.value) {
      this.runningUser$.next(true);
      const userDoc = doc(this.usersCollection, user.id.toString());

      await this.initRealTimeDevice();

      await runTransaction(this.firestore, transaction =>
        transaction.get(userDoc).then(uDoc => {
          if (!uDoc.exists) {
          }
          const cleanUser = this.cleanUserData(user);
          cleanUser.lastLogin = serverTimestamp();
          transaction.set(userDoc, cleanUser, { merge: true });
        })
      )
        .then(() => {})
        .catch(error => {});

      this.runningUser$.next(false);

      return this.getRealTimeUserData(user);
    } else {
      return this.runningUser$
        .pipe(
          take(1),
          switchMap(() => this.getRealTimeUserData(user))
        )
        .toPromise();
    }
  }

  async getRealTimeUserData(user: UserSessionResponseInterface): Promise<UserRealTimeInterface> {
    const userDoc = doc<UserRealTimeInterface>(this.usersCollection, user.id.toString());
    return from(getDoc(userDoc))
      .pipe(take(1), map(validateSnapshot))
      .toPromise()
      .catch(error => {
        TrackJS.console.error('Error on get realtime user data', error);
        return {} as UserRealTimeInterface;
      });
  }

  async getRealTimeDeviceData(device: IDevice): Promise<IDevice> {
    const docRef: DocumentReference<IDevice> = doc(this.devicesCollection, device.duid.toString());
    return from(getDoc(docRef))
      .pipe(
        take(1),
        map(validateSnapshot),
        tap(response => {
          this.currentDevice = response;
        })
      )
      .toPromise()
      .catch(error => {
        TrackJS.console.error('error on get realtime device data', error);
        return {} as IDevice;
      });
  }

  async updateRealtimeUserData(user: UserRealTimeInterface, data: any): Promise<void> {
    const userDoc = doc(this.usersCollection, user.id.toString());
    return updateDoc(userDoc, data);
  }

  async updateRealtimeDeviceData(device: IDevice, data: any): Promise<void> {
    const docRef = doc(this.devicesCollection, device.duid.toString());
    return updateDoc(docRef, data);
  }
}
