import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { MovistarHomeComponent } from './movistar-home.component';

export const movistarHomeState: Route = {
  path: '',
  component: MovistarHomeComponent,
};
export const movistarHomeStates: Route[] = [movistarHomeState];

@NgModule({
  imports: [RouterModule.forChild(movistarHomeStates)],
  exports: [RouterModule],
  providers: [],
})
export class MovistarHomeRoutingModule {}
