export class Sticker {
  id: any;
  name: string;
  img?: string;
  url?: string;
  position: any = { x: 0, y: 0 };
  type_s?: number;
  state = 'inactive';
  value: any;
  duration = 1500;
  owner: string;
  timeout: any;
  maxX;
  maxY;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    const stickerSize = window.innerWidth * 0.1;
    this.maxX = window.innerWidth - stickerSize;
    this.maxY = window.innerHeight - stickerSize;
    for (const f in fields) {
      if (fields[f]) {
        this[f] = fields[f];
      }
    }
  }

  show(timeout?: number) {
    this.state = 'inactive';
    this.timeout = setTimeout(() => {
      this.state = 'active';
      this.timeout = setTimeout(() => {
        this.state = 'inactive';
        clearTimeout(this.timeout);
        this.timeout = null;
      }, this.duration || 1500);
    }, 20);
  }

  setRamdomPosition(): any {
    const randomPosX = Math.floor(Math.random() * this.maxX);
    const randomPosY = Math.floor(Math.random() * this.maxY);

    this.position = {
      x: randomPosX,
      y: randomPosY,
    };
  }
}
