import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Alertify } from '../alertify/alertify';
import { getPictureUrlFromProfile } from '../helpers/helpers';

@Injectable()
export class Notifications {
  notificationMessages: any = {};

  constructor(private alertify: Alertify, private translate: TranslateService) {
    this.alertify.setDelay(5000);
    this.alertify.setLogPosition('top left');

    const notifications = ['NOTIFICATIONS'];

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(notifications).subscribe(translations => {
        this.notificationMessages = translations;
      });
    });
  }
  notify(mode: string, data?: any, delay?: number) {
    this.alertify.setDelay(delay || 5000);
    this.alertify.setLogPosition('top right');
    const msg = this.getTextMessage(mode, data);
    this.alertify.log(msg);
  }
  notifyUserMessage(picture: string, user: string, text: string) {
    this.alertify.setDelay(8000);
    this.alertify.setLogPosition('down left');
    const msg = this.msgUserTemplate(picture, user, text);
    this.alertify.log(msg);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgTemplate(icon: any, htmlText: any, lineClass?: any, img?: any) {
    lineClass = lineClass === undefined ? '' : lineClass;
    return (
      '<div class="notification">' +
      '<div class="wrapicon">' +
      '<span class="' +
      icon +
      '"></span>' +
      '</div>' +
      '<p class="notification-text ' +
      lineClass +
      '">' +
      htmlText +
      '</p>' +
      '</div>'
    );
  }

  msgReportSongTemplate(icon: any, htmlText: any, lineClass?: any) {
    lineClass = lineClass === undefined ? '' : lineClass;
    return (
      '<div class="notification report-song">' +
      '<div class="wrapicon">' +
      '<span class="' +
      icon +
      '"></span>' +
      '</div>' +
      '<p class="notification-text ' +
      lineClass +
      '">' +
      htmlText +
      '</p>' +
      '</div>'
    );
  }

  msgFreemiumTemplate(icon: any, htmlText: any, lineClass?: any, img?: any) {
    lineClass = lineClass === undefined ? '' : lineClass;
    return (
      '<div class="notification">' +
      '<div class="wrapicon wrapicon-freemium">' +
      '<span class="' +
      icon +
      '"></span>' +
      '</div>' +
      '<p class="notification-text freemiun-text' +
      lineClass +
      '">' +
      htmlText +
      '</p>' +
      '</div>'
    );
  }
  msgPhotoTemplate(icon: any, htmlText: any, lineClass?: any, img?: any) {
    lineClass = lineClass === undefined ? '' : lineClass;
    return (
      '<div class="notification">' +
      '<div class="wrapicon">' +
      '<span style="background-image: url(' +
      img +
      ');" class="' +
      icon +
      '"></span>' +
      '</div>' +
      '<p class="notification-text ' +
      lineClass +
      '">' +
      htmlText +
      '</p>' +
      '</div>'
    );
  }

  msgAddSongToListFriendsTemplate(icon: any, htmlText: any, lineClass?: any, img?: any) {
    lineClass = lineClass === undefined ? '' : lineClass;
    return (
      '<div class="notification">' +
      '<div class="wrapicon">' +
      '<span style="background-image: url(' +
      img +
      ');" class="' +
      icon +
      '"></span>' +
      '<span class="addlist-icon"></span></div>' +
      '<p class="notification-text ' +
      lineClass +
      '">' +
      htmlText +
      '</p>' +
      '</div>'
    );
  }

  msgUserTemplate(picture: string, user: string, msg: string, lineClass?: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lineClass = lineClass === undefined ? '' : lineClass;
    return `<div class='notification-message'>
                            <div class='user'>
                                <img class='profile-picture' src="${picture}" alt="${
      user[0] ? user[0].toLocaleUpperCase() : ''
    }"/>
                            </div>
                            <div class='message-text'>
                              <h2 class='username'>${user}</h2>
                              <p>${msg}</p>
                            </div>
                    </div>`;
  }
  getTextMessage(typ: string, data?: any) {
    let msg;
    let msgHTML;
    switch (typ) {
      // case 'addSmartList':
      // msgHTML = this.notificationMessages["NOTIFICATIONS"]["addSmartList"];
      // msg = this.msgTemplate('smart-list-icon', msgHTML);
      // break;
      case 'addSmartList':
        if (!data) {
          msgHTML = this.notificationMessages['NOTIFICATIONS']['addSmartList'];
        } else {
          msgHTML = `${this.notificationMessages['NOTIFICATIONS']['addSong']}
          <strong>${data.name}</strong> -
          <strong>${data.artist}</strong> ${this.notificationMessages['NOTIFICATIONS']['toList']}`;
        }
        msg = this.msgTemplate('smart-list-icon', msgHTML);
        break;
      case 'addSmartListFriends':
        if (!data) {
          msgHTML = this.notificationMessages['NOTIFICATIONS']['addSmartList'];
        } else {
          msgHTML = `
          <strong>${data.user_name}</strong>
          ${this.notificationMessages['NOTIFICATIONS']['addedSong']}
          <strong>${data.name}</strong> -
          <strong>${data.artist}</strong> ${this.notificationMessages['NOTIFICATIONS']['toList']}`;
        }
        const img = getPictureUrlFromProfile(data);
        msg = this.msgAddSongToListFriendsTemplate('photo', msgHTML, '', img);
        break;
      case 'addFriendList':
        msgHTML =
          `<strong>` +
          (data.userNotification || data.name || 'Una persona') +
          `</strong> ${this.notificationMessages['NOTIFICATIONS']['jointoTv']}` +
          `<strong> ${data.tvOwner}</strong>${this.notificationMessages['NOTIFICATIONS']['textForEnglish']}`;
        msg = this.msgPhotoTemplate('photo', msgHTML, '', data.userImg);
        break;
      case 'followUser':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['followUser']} <b>${data.username}</b>.<br/>
        ${this.notificationMessages['NOTIFICATIONS']['followUserNext']}`;
        msg = this.msgTemplate('follow-icon', msgHTML, 'two-line');
        break;
      case 'readyForStickers':
        msgHTML = '¡Envíen stickers desde el celular para calificar al cantante!';
        msg = this.msgTemplate('check-icon', msgHTML);
        break;
      case 'registerFreemiun':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['register_freemium'];
        msg = this.msgTemplate('register-freemium', msgHTML);
        break;
      case 'reportSong':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['REPORT_SONG'];
        msg = this.msgReportSongTemplate('report-song', msgHTML);
        break;
      case 'registerFreemiunLike':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Like'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Like_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Like_3'];
        msg = this.msgFreemiumTemplate('register-freemium-like', msgHTML);
        break;
      case 'registerFreemiunFollow':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Follow'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Follow_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Follow_3'];
        msg = this.msgFreemiumTemplate('register-freemium-follow', msgHTML);
        break;
      case 'registerFreemiunFavorites':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Favorites'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Favorites_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Favorites_3'];
        msg = this.msgFreemiumTemplate('register-freemium-favorites', msgHTML);
        break;
      case 'registerFreemiunConnect':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Connect'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Connect_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Connect_3'];
        msg = this.msgFreemiumTemplate('register-freemium-connect', msgHTML);
        break;
      case 'registerFreemiunSing':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing_3'];
        msg = this.msgFreemiumTemplate('register-freemium-music', msgHTML);
        break;
      case 'registerFreemiunSingEN':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing_3'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Sing_4'] +
          `</span>.`;
        msg = this.msgFreemiumTemplate('register-freemium-music', msgHTML);
        break;
      case 'registerFreemiunQueue':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue_3'];
        msg = this.msgFreemiumTemplate('register-freemium-music', msgHTML);
        break;
      case 'registerFreemiunQueueEN':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue_2'] +
          ` </span>` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue_3'] +
          `<span style="color: #5037F1;"> ` +
          this.notificationMessages['NOTIFICATIONS']['REGISTER_FREEMIUM_Queue_4'] +
          `</span>.`;
        msg = this.msgFreemiumTemplate('register-freemium-music', msgHTML);
        break;
      case 'nowYouCanSing':
        msgHTML = '¡Ahora todos pueden cantar sin límites!';
        msg = this.msgTemplate('check-icon', msgHTML);
        break;
      case 'errorOnVideo':
        msgHTML =
          '¡Ha ocurrido un error con el video, a continuación se reproducirá la siguiente canción de la lista ¡a cantar!';
        msg = this.msgTemplate('exclamation-icon', msgHTML);
        break;
      case 'errorCustom':
        msgHTML = data.message || data.error || 'Ha ocurrido un error';
        msg = this.msgTemplate('exclamation-icon', msgHTML);
        break;

      case 'onlyPremium':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['onlyPremium'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'errorGateway':
        msgHTML = data.message;
        msg = this.msgTemplate('exclamation-icon', msgHTML);
        break;
      case 'redirectToGateway':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['redirectToGateway'];
        msg = this.msgTemplate('exclamation-icon', msgHTML);
        break;
      case 'addFavorite':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['added']}
        <strong>${data.name}</strong> -
        <strong>${data.artist}</strong> ${this.notificationMessages['NOTIFICATIONS']['toYourFavorites']}`;
        // msgHTML = data.message;
        msg = this.msgTemplate('addfavorite-icon', msgHTML, 'two-line');
        break;
      case 'removeFavorite':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['removed']}
          <strong>${data.name}</strong> -
          <strong>${data.artist}</strong> ${this.notificationMessages['NOTIFICATIONS']['ofYourFavorites']}`;
        // msgHTML = data.message;
        msg = this.msgTemplate('addfavorite-icon', msgHTML, 'two-line');
        break;
      case 'addArtistFavorite':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['added']}
        <strong>${data.artist}</strong> ${this.notificationMessages['NOTIFICATIONS']['toYourFavorites']}`;
        // msgHTML = data.message;
        msg = this.msgTemplate('addfavorite-icon', msgHTML, 'two-line');
        break;
      case 'removeArtistFavorite':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['removed']}
        <strong>${data.artist}</strong> ${this.notificationMessages['NOTIFICATIONS']['ofYourFavorites']}`;
        // msgHTML = data.message;
        msg = this.msgTemplate('addfavorite-icon', msgHTML, 'two-line');
        break;
      case 'addPlaylistFavorite':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['added']}
          <strong>${data.playlist}</strong> ${this.notificationMessages['NOTIFICATIONS']['toYourFavorites']}`;
        // msgHTML = data.message;
        msg = this.msgTemplate('addfavorite-icon', msgHTML, 'two-line');
        break;
      case 'removePlaylistFavorite':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['removed']}
          <strong>${data.playlist}</strong> ${this.notificationMessages['NOTIFICATIONS']['ofYourFavorites']}`;
        // msgHTML = data.message;
        msg = this.msgTemplate('addfavorite-icon', msgHTML, 'two-line');
        break;
      case 'requestSong':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['requestSong'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        break;
      case 'promoTicketSingSong':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['promoTicketSingSong'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        break;
      case 'smsSended':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['sms_sended'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        break;
      case 'smsInvalidNumberError':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['sms_invalid_number_error'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'smsSendMessageError':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['sms_send_message_error'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'smsCodeVerificationError':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['sms_code_verification_error'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'smsFastPurchase':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['sms_fast_purchase'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        break;
      case 'tryConnectFail':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['tryAddFavorite'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        break;
      case 'tryAddFavorite':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['tryAddFavorite'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'onlyPremiumPlay':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['onlyPremiumPlay'];
        msg = this.msgTemplate('lock-icon', msgHTML, 'two-line');
        break;
      case 'onlyPremiumAdd':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['onlyPremiumAdd'];
        msg = this.msgTemplate('lock-icon', msgHTML, 'two-line');
        break;
      case 'onlyPremiumPlaylist':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['onlyPremiumPlaylist'];
        msg = this.msgTemplate('lock-icon', msgHTML, 'two-line');
        break;
      case 'winTickets':
        msgHTML =
          this.notificationMessages['NOTIFICATIONS']['winTickets1'] +
          data.number +
          this.notificationMessages['NOTIFICATIONS']['winTickets2'];
        msg = this.msgTemplate('concert-icon', msgHTML, 'two-line');
        break;
      case 'winTicketsWelcome':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['winTicketsWelcome'];
        msg = this.msgTemplate('concert-icon', msgHTML, 'two-line');
        break;
      case 'TicketsWelcome':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['TicketsWelcome'];
        msg = this.msgTemplate('concert-icon', msgHTML, 'two-line');
        break;

      case 'removeFriendList':
        msgHTML =
          '<strong>' +
          (data.userNotification || data.nickname) +
          '</strong> abandonó la ' +
          '<span style="color: #f42434">FIESTA</span>.';
        msg = this.msgTemplate('lessfriend-icon', msgHTML);
        break;
      case 'afterWelcomeModal':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['afterWelcomeModal'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        break;
      case 'inviteFriends':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['inviteFriends'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'alertNoSongs':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['alert_No_Songs'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        // sendNotify(defaultTimeDelay, msg);
        break;
      case 'favoritesVoid':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['favoritesVoid'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'otherListVoid':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['otherListVoid'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'welcomeExplorer':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['welcomeExplorer'];
        msg = this.msgTemplate('check-icon', msgHTML, 'two-line');
        setTimeout(() => {
          this.notify('downloadApp');
        }, 20000);
        break;
      case 'downloadApp':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['downloadApp'];
        msg = this.msgTemplate('appmovil-icon', msgHTML, 'two-line');
        break;
      case 'registerPassMail':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['registerPassMail'];
        msg = this.msgTemplate('email-icon', msgHTML, 'two-line');
        break;
      case 'failPaymentMail':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['registerPassMail'];
        msg = this.msgTemplate('email-icon', msgHTML, 'two-line');
        break;
      case 'resetPassMail':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['resetPassMail'];
        msg = this.msgTemplate('email-icon', msgHTML, 'two-line');
        break;
      case 'failresetPassMail':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['failresetPassMail'];
        msg = this.msgTemplate('check-icon', msgHTML);
        break;
      case 'slowNetwork':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['slowNetwork'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'notAvailableOnCountry':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['notAvailableOnCountry'];
        msg = this.msgTemplate('exclamation-icon', msgHTML, 'two-line');
        break;
      case 'errorCheck':
        msgHTML = data.message;
        msg = this.msgTemplate('exclamation-icon', msgHTML);
        break;
      case 'custom':
        msgHTML = data.message;
        msg = this.msgTemplate(data.icon || 'check-icon', msgHTML, data.lineText || 'two-line');
        break;
      case 'customMsj':
        msgHTML = this.notificationMessages['NOTIFICATIONS']['smsSended'];
        msg = this.msgTemplate('msj-icon', msgHTML, 'two-line');
        break;
      case 'likeUser':
        msgHTML = `${this.notificationMessages['NOTIFICATIONS']['likeRecord']}
        <strong> ${data.username}</strong>${this.notificationMessages['NOTIFICATIONS']['likeRecordEnglish']}`;
        msg = this.msgTemplate('like', msgHTML, 'two-line');
        break;
      default:
    }
    return msg;
  }
}
