import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ABTestingService } from '../ab-testing/ab-testing.service';
import { Config } from '../config/config';
import { CountryService } from '../country/country';
import { MetricsService } from '../metrics/metrics.service';
import { RootScopeAdapter } from '../root-scope-adapter/root-scope-adapter';
import { User } from '../user/user';

@Injectable()
export class ModalService {
  active = '';
  data = {};
  modalActive = false;
  hostname = (window as any).location.hostname;
  tvBelongsPromoLg = false;
  background = './images/app/fondos/modal_init_pay_B.jpg';
  countriesPersonalizedPrice = {
    PE: '../images/app/fondos/modal_init_pay_B_PE.jpg',
    EC: '../images/app/fondos/modal_init_pay_B_EC.jpg',
    MX: '../images/app/fondos/modal_init_pay_B_MX.jpg',
    CO: '../images/app/fondos/modal_init_pay_B_CO.jpg',
    BR: '../images/app/fondos/modal_init_pay_B_BR.jpg',
    AR: '../images/app/fondos/modal_pagoAR.jpg',
  };
  constructor(
    private config: Config,
    private metrics: MetricsService,
    private router: Router,
    private user: User,
    private country: CountryService,
    private rootScopeAdapter: RootScopeAdapter,
    private abTestingService: ABTestingService
  ) {
    this.setModal();
  }

  setModal() {
    const countrySub = this.country.onReady(res => {
      const country = this.country.currentCountry;
      console.log(country, 'country country');
      if (country && this.countriesPersonalizedPrice[country]) {
        this.background = this.countriesPersonalizedPrice[country];
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      countrySub ? countrySub.unsubscribe() : null;
    });
  }
  action1() {
    this.stateModal('', false);

    if (this.user.authenticated) {
      this.router.navigate(['subscription']);
    } else {
      console.log('navigate to login');
      this.router
        .navigate(['login', 'web'], {
          queryParams: {
            from: 'welcome',
            mode: 'register',
            action: 'WelcomeCTA',
          },
        })
        .catch(e => {
          console.log('error navi', e);
        });
    }
  }
  action2(to?) {
    if (this.user.currentUser) {
      this.metrics.sendEvent('logout');
      this.user.logout().then(() => {
        this.rootScopeAdapter.logout.next(true);
      });
    } else {
      this.stateModal('', false);

      this.router.navigate(['login', 'web'], {
        queryParams: {
          from: 'welcome',
          mode: 'login',
          action: 'WelcomeCTA',
        },
      });
    }
  }
  getStateModal() {
    return { active: this.active, modalActive: this.modalActive };
  }
  stateModal(name, stateBoolean, data?) {
    this.active = name;
    this.modalActive = stateBoolean;
    console.log(name, stateBoolean, data);
    if (data) {
      this.data = data;
    }

    if (!stateBoolean) {
      this.data = null;
    }
  }
  goCoupon() {
    this.stateModal('', false);

    this.rootScopeAdapter.sendViewClicked('ks.coupons', { from: 'modal' });
    this.router.navigate(['coupons']);
  }
}
