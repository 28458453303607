import { Injectable } from '@angular/core';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { LocalStorageInterface } from '@providers/local-storage/local-storage.interface';

@Injectable()
export class MovistarLocalStorageService extends LocalStorage implements LocalStorageInterface {
  async getItem(key: string) {
    if (!this.ready) {
      throw new Error('Movistar SDK is not ready');
    }

    let value = this.cacheStorage[key];

    if (!value) {
      value = await window.laSdk.persistenceRead(key);
      this.cacheStorage[key] = value;
    }
    return value;
  }

  async setItem(key: string, value: any) {
    if (!this.ready) {
      throw new Error('Movistar SDK is not ready');
    }
    if (this.cacheStorage[key] === value) {
      return;
    }
    this.cacheStorage[key] = value;

    return window.laSdk.persistenceWrite(key, value).then(() => {
      this.cacheStorage[key] = value;
    });
  }

  async removeItem(key: string): Promise<void> {
    await window.laSdk.persistenceWrite(key, '');
    return super.removeItem(key);
  }

  async removeAll(): Promise<void> {
    return super.removeAll();
  }
}
