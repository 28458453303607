import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IRemoteAction } from '@interfaces/remote-action.interface';
import {
  collection,
  doc,
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { documentFromOnSnapshot, valueChanges } from '@providers/helpers/firestore';
import { SessionService } from '@providers/user/session.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RemoteScoreStatusService } from './remote-score-status.service';

@Injectable()
export class RemoteActionsService {
  currentActionsDoc: DocumentReference<IRemoteAction>;
  currentActionObservable: Observable<IRemoteAction>;

  currentActionSubscription: Subscription;

  remoteActions$: Subject<IRemoteAction> = new Subject<IRemoteAction>();

  constructor(
    private session: SessionService,
    private router: Router,
    private remoteScoreStatus: RemoteScoreStatusService
  ) {}

  subscribeToRemoteActions() {
    this.unsubscribeToRemoteActions();
    const smartListCollection = collection(this.session.sessionDoc, 'smartList');
    this.currentActionsDoc = doc(smartListCollection, 'currentAction') as DocumentReference<IRemoteAction>;
    this.currentActionObservable = documentFromOnSnapshot(this.currentActionsDoc).pipe(valueChanges<IRemoteAction>());
    this.currentActionSubscription = this.currentActionObservable
      .pipe(filter(action => !action?.done))
      .subscribe(action => {
        this.processAction(action);
      });
  }

  async processAction(action: IRemoteAction): Promise<void> {
    switch (action.action) {
      case 'replay': {
        break;
      }
      case 'start_sound_check': {
        if (action.data?.song_uid) {
          this.router
            .navigate(['init-score/start-game'], {
              queryParams: { uid: action.data.song_uid },
            })
            .then(async () => {
              await this.markActionAsDone(action);
            });
        }

        break;
      }
      case 'show_result': {
        break;
      }
      case 'sound_check_ok': {
        this.remoteScoreStatus.setScoreState('TV_READY_TO_SCORE');
        break;
      }
      case 'latency_test': {
        break;
      }

      case 'quit_score': {
        break;
      }
    }
    if (action.action === 'quit_score') {
      if (this.remoteScoreStatus.currentScoreStatus.deviceId === action.deviceId) {
        this.remoteActions$.next(action);
      }
    } else {
      this.remoteActions$.next(action);
    }
    const actionsForMarkingAsDoneInmediately = [
      'replay',
      'show_result',
      'sound_check_ok',
      'latency_test',
      'quit_score',
    ];
    if (actionsForMarkingAsDoneInmediately.includes(action.action)) {
      await this.markActionAsDone(action);
    }
  }

  unsubscribeToRemoteActions() {
    this.currentActionSubscription?.unsubscribe();
  }

  markActionAsDone(action: IRemoteAction): Promise<any> {
    return updateDoc(this.currentActionsDoc, {
      done: true,
      updatedAt: serverTimestamp(),
    });
  }
}
