import { Injectable } from '@angular/core';
declare const hj: any;

@Injectable()
export class HotjarService {
  constructor() {}

  identify(data, userId) {
    hj('identify', userId || null, data);
  }
}
