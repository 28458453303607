// angular
import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { StateTransferInitializerModule } from '@nguniversal/common';
// libs
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ChromecastService } from '@providers/chromecast/chromecast.service';
import {
  devicePlatformBootstrapFactory,
  DevicePlatformInitializer,
} from '@providers/device-ks/device-platform.initializer';

// shared
// import { TranslatesBrowserModule } from '@shared/translates/translates-browser';
// components
import { AppComponent } from './app.component';
import { AppModule } from './app.module';

// the Request object only lives on the server
export const getRequest = (): any => ({ headers: { cookie: document.cookie } });

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    AppModule,
    StateTransferInitializerModule,
    BrowserTransferStateModule,
    // TranslatesBrowserModule,
    // InlineStyleModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
  ],
  providers: [
    ChromecastService,
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: devicePlatformBootstrapFactory,
      deps: [DevicePlatformInitializer],
      multi: true,
    },
    {
      // The server provides these in main.server
      provide: REQUEST,
      useFactory: getRequest,
    },
    { provide: 'ORIGIN_URL', useValue: location.origin },
  ],
})
export class AppBrowserModule {}
