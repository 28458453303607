import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { CookiesService } from '../cookies/cookies.service';
import { Platform } from '../platform/platform';
import { LocalStorageInterface } from './local-storage.interface';

@Injectable()
export class LocalStorage implements LocalStorageInterface {
  prefix = 'ngStorage-';
  localStorageNotAvailable = false;

  localStorage: Storage;
  $ready = new BehaviorSubject<boolean>(false);
  $isReady = this.$ready.pipe(
    filter(ready => ready),
    take(1)
  );

  cacheStorage: {
    [key: string]: any;
  } = {};

  constructor(protected platform: Platform, protected cookies: CookiesService) {
    if (!localStorage) {
      this.localStorageNotAvailable = true;
      this.localStorage = (window as any).memoryStorage;
    } else {
      this.localStorage = localStorage;
    }
  }

  get ready(): boolean {
    return this.$ready.value;
  }

  getCachedItem(key: string) {
    return this.cacheStorage[key];
  }

  async getItem(key: string): Promise<any> {
    if (this.cacheStorage[key]) {
      return this.cacheStorage[key];
    }

    if (this.platform.isBrowser()) {
      const value = this.parseValue(this.cookies.get(key)) || this.localStorage.getItem(key);
      this.cacheStorage[key] = value;
      return value;
    } else {
      const value = this.parseValue(this.cookies.get(key));
      this.cacheStorage[key] = value;
      return value;
    }
  }

  async setItem(key: string, value: any): Promise<void> {
    if (this.platform.isBrowser()) {
      this.localStorage.setItem(key, value);
      this.cookies.set(key, value);
      this.cacheStorage[key] = this.parseValue(value);
      return;
    } else {
      this.cookies.set(key, value);
      this.cacheStorage[key] = this.parseValue(value);
      return;
    }
  }

  parseValue(value: any) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  removeItem(key: string) {
    if (this.platform.isBrowser()) {
      this.cookies.delete(key);
      this.localStorage.removeItem(key);
      this.localStorage.removeItem(this.prefix + key);
      this.cacheStorage[key] = null;
      delete this.cacheStorage[key];
    } else {
      this.cookies.delete(key);
      this.cacheStorage[key] = null;
      delete this.cacheStorage[key];
    }
  }

  removeAll() {
    if (this.platform.isBrowser()) {
      this.localStorage.clear();
      this.cookies.deleteAll();
      this.cacheStorage = {};
    } else {
      this.cookies.deleteAll();
      this.cacheStorage = {};
    }
  }
}
