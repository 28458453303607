export class Photo {
  id: string;
  image: string;
  user: string;
  position: any;
  type_s?: number;
  state?: string;
  maxX;
  maxY;
  photoSize = 300;
  // value:any;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    if (window.screen.width > 1500) {
      this.photoSize = 500;
    }

    this.maxX = window.innerWidth - this.photoSize;
    this.maxY = window.innerHeight - this.photoSize;
    for (const f in fields) {
      if (fields[f]) {
        this[f] = fields[f];
      }
    }
  }

  setRamdomPosition(): any {
    const randomPosX = Math.floor(Math.random() * this.maxX);
    const randomPosY = Math.floor(Math.random() * this.maxY);
    this.position = {
      x: randomPosX,
      y: randomPosY,
    };
  }
}
