import { CookiesService } from '@providers/cookies/cookies.service';
import { DeviceDetectorService } from '@providers/device-ks/device-detector.service';
import { MovistarLocalStorageService } from '@providers/device-ks/movistar/movistar-local-storage.service';
import { Platform } from '@providers/platform/platform';

import { LocalStorage } from './local-storage';

export const localStorageFactory = (
  platform: Platform,
  cookies: CookiesService,
  deviceDetector: DeviceDetectorService
) => {
  if (deviceDetector.checkIfMovistar()) {
    return new MovistarLocalStorageService(platform, cookies);
  } else {
    return new LocalStorage(platform, cookies);
  }
};
