import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { RankingResponseInterface, RankingUserInterface } from '../../interfaces/ranking.interface';
import { Api } from '../api/api';
import { PlaylistService } from '../playlist/playlist';

@Injectable()
export class RankingService {
  constructor(private api: Api, private playList: PlaylistService) {}

  getTop(): Promise<RankingResponseInterface> {
    return this.api
      .get('game/top')
      .pipe(
        map(res => {
          if (res.success === true) {
            return res.data;
          } else {
            throw throwError(res.message || 'Ocurrio un error inesperado');
          }
        }, this.handleError)
      )
      .toPromise();
  }

  getTopSongs() {
    return this.api.get('game/topsong?size=5').pipe(
      map(res => {
        if (res.success) {
          return res.data;
        } else {
          throw throwError(res.message || 'Ocurrio un error inesperado');
        }
      })
    );
  }

  getTopSingers(): Observable<RankingUserInterface[]> {
    return this.api.get('game/top?size=5').pipe(
      map(res => {
        if (res.success) {
          return res.data.global;
        } else {
          throw throwError(res.message || 'Ocurrio un error inesperado');
        }
      })
    );
  }

  singSong(song) {
    return this.api
      .post('game/singsong', {
        action_id: 1,
        song_id: song.id,
      })
      .toPromise()
      .then(res =>
        // RECIBIR LA CANTIDAD DE CANCIONES
        res.success ? res.data.songs : 0
      )
      .catch(err => {
        console.log('error al obtener ranking', err);
        return false;
      });
  }

  init() {
    return this.api
      .get('game/init')
      .toPromise()
      .then(res => res)
      .catch(err => {
        console.log('error al obtener ranking', err);
        return false;
      });
  }

  handleError(err) {
    throw err.error ? err : 'Ocurrio un error inesperado aca';
  }
}
