import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SmartRouterAnimationWrapComponent } from './smart-router-animation-wrap.component';
@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [SmartRouterAnimationWrapComponent],
  declarations: [SmartRouterAnimationWrapComponent],
})
export class SmartRouterAnimationWrapModule {
  static forRoot(): ModuleWithProviders<SmartRouterAnimationWrapModule> {
    return {
      ngModule: SmartRouterAnimationWrapModule,
    };
  }
}
