import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DeviceKS, GtvZone } from '@providers/providers';

@Component({
  selector: 'app-movistar-home',
  templateUrl: './movistar-home.component.html',
  styleUrls: ['./movistar-home.component.scss'],
})
export class MovistarHomeComponent implements OnInit, AfterViewInit {
  routes = [
    { screen: 'volver', text: 'Volver' },
    { screen: 'search', text: 'Buscar' },
    { screen: '', text: 'Explora' },
    { screen: 'live', text: 'Comunidad' },
    { screen: 'profile', text: 'Perfil' },
  ];

  constructor(private deviceKs: DeviceKS, private gtvzone: GtvZone) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.activate();
  }

  sendDataToStb(screen: string) {
    //@ts-ignore
    this.deviceKs.devicePlatform?.sendToSTB({
      origin: 'mh',
      toScreen: screen,
    });
  }

  activate() {
    const noAction = () => this.gtvzone.noAction;
    const horizontal = noAction;

    this.gtvzone.createZone('GENERIC_LAYER', 'mh-screen-wrpper', '#mh-screen-wrpper', {
      left: horizontal,
      up: selectedItem => {
        if (selectedItem.hasClass('mh-btn-1')) {
          return this.gtvzone.skipAction;
        }
        return this.gtvzone.noAction;
      },
      right: horizontal,
      down: selectedItem => {
        if (selectedItem.hasClass('mh-btn-' + this.routes.length)) {
          return this.gtvzone.skipAction;
        }
        return this.gtvzone.noAction;
      },
    });
    const select = '#mh-btn-1';

    this.gtvzone.setDefaultSelected(select);
  }
}
