import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { debounceTime, defaultIfEmpty, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Api } from '../api/api';

export interface SmsResponseInterface {
  status: string;
  error_id?: string;
  error_msg?: string;
}

export interface ResultVerificationPhoneResponseInterface {
  verified: boolean;
  previously_verified: boolean;
  tries_remaining: number;
  message: string;
}

export interface VerificationPhoneResponseInterface {
  status: string;
  result?: ResultVerificationPhoneResponseInterface;
  error_id?: string;
  error_msg?: string;
}

@Injectable()
export class SmsService {
  phone: Subject<string> = new Subject();
  code: Subject<string> = new Subject();
  working: Subject<boolean> = new Subject();
  smsPhoneObserver: Observable<SmsResponseInterface>;
  smsCodeObserver: Observable<VerificationPhoneResponseInterface>;
  lastPhone: string;
  constructor(private api: Api) {
    this.smsPhoneObserver = this.processSMSVerification(this.phone);
    this.smsCodeObserver = this.processCodeVerification(this.code);
  }

  processSMSVerification(phone: Observable<string>): Observable<SmsResponseInterface> {
    return phone.pipe(
      debounceTime(500),
      // distinctUntilChanged(),
      defaultIfEmpty(null),
      switchMap(phone_number => {
        this.working.next(true);
        this.lastPhone = phone_number;
        if (!phone_number || phone_number === '51') {
          this.working.next(false);
          return throwError({ error_id: 'EMPTY_NUMBER' });
        }
        return this.requestSMSVerification(phone_number);
      })
    );
  }

  sendSMSVerification(phone_number: string) {
    this.phone.next(phone_number);
  }

  requestSMSVerification(phone: string): Observable<SmsResponseInterface> {
    return this.api
      .post('sms/send', {
        phone_number: phone,
      })
      .pipe(
        switchMap(response => {
          if (response.success === true) {
            return of(response.data);
          } else {
            return throwError(response);
          }
        }),
        switchMap((response: SmsResponseInterface) => {
          if (response.status === 'ok') {
            this.working.next(false);
            return of(response);
          } else {
            this.working.next(false);
            return throwError(response);
          }
        })
      );
  }
  verifyPhone(phone: string, code: string) {
    this.code.next(code);
  }

  processCodeVerification(code: Observable<string>): Observable<VerificationPhoneResponseInterface> {
    return code.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(codigo => {
        this.working.next(true);
        return this.requestVerifyPhone(this.lastPhone, codigo);
      })
    );
  }

  requestVerifyPhone(phone: string, code: string): Observable<VerificationPhoneResponseInterface> {
    return this.api
      .post('sms/verify', {
        phone_number: phone,
        pin: code,
      })
      .pipe(
        switchMap(response => {
          if (response.success === true) {
            return of(response.data);
          } else {
            return throwError(response);
          }
        }),
        switchMap((response: VerificationPhoneResponseInterface) => {
          if (response.status === 'ok' && response.result.verified) {
            return of(response);
          } else {
            return throwError(response);
          }
        })
      );
  }
}
