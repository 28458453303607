import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DeviceKS } from '../device-ks/device-ks';
import { LocalStorage } from '../local-storage/local-storage';

@Injectable()
export class KSHelpers {
  bands = {
    BR: './images/app/iconos/banderasModal/brasil.png',
    PE: './images/app/iconos/banderasModal/peru.png',
    AR: './images/app/iconos/banderasModal/argentina.png',
    CH: './images/app/iconos/banderasModal/chile.png',
    CO: './images/app/iconos/banderasModal/colombia.png',
    EC: './images/app/iconos/banderasModal/ecuador.png',
    MX: './images/app/iconos/banderasModal/mexico.png',
  };

  countries = {
    pe: '0800-76766',
    ar: '0800-2220652',
    co: '01-800-5185330',
    cl: '1-888-00201446',
    mx: '01-800-0993487',
    br: '0800-7618621',
  };

  constructor(private localStorage: LocalStorage, private deviceKS: DeviceKS, private translate: TranslateService) {}

  evaluateRegex(text) {
    const reg =
      // eslint-disable-next-line max-len
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(text);
  }

  getLanguages() {
    const langTv = this.translate.getBrowserLang();
    let useLang = 'es';
    if (langTv.indexOf('pt') !== -1) {
      useLang = 'pt';
    }
    if (langTv.indexOf('en') !== -1) {
      useLang = 'en';
    }
    if (langTv.indexOf('pt') !== -1) {
      useLang = 'pt';
    }
    return useLang;
  }

  keyboardNumeric() {
    return [
      {
        word: '1',
      },
      {
        word: '2',
      },
      {
        word: '3',
      },
      {
        word: '4',
      },
      {
        word: '5',
        parentClass: 'defaultSelect',
      },
      {
        word: '6',
      },
      {
        word: '7',
      },
      {
        word: '8',
      },
      {
        word: '9',
      },
      {
        word: '0',
        parentClass: 'zero-number',
      },
      {
        icon: 'backspace-icon sprite-ks',
        parentClass: 'medium-icon',
        word: -1,
      },
    ];
  }

  keyboardsCounpon() {
    return [
      {
        word: '1',
        parentClass: 'defaultSelect',
      },
      {
        word: '2',
      },
      {
        word: '3',
      },
      {
        word: '4',
      },
      {
        word: '5',
      },
      {
        word: '6',
      },
      {
        word: '7',
      },
      {
        word: '8',
      },
      {
        word: '9',
      },
      {
        word: '0',
      },
      {
        icon: 'backspace-icon sprite-ks',
        parentClass: 'medium-icon',
        word: -1,
      },
      {
        action: 'cancel',
        word: 'ERASE_ALL',
        parentClass: 'ant-next btn-back-all-class',
      },
      {
        action: 'send',
        word: 'Ingresar',
        parentClass: 'ant-next btn-right-keyboard btn-enter-class',
      },
    ];
  }
  keyboardLang(keyboard) {
    const keyboardAux = keyboard;
    if ((window as any).useLang === 'pt') {
      for (const item of keyboardAux) {
        if (item.parentClass) {
          if (item.parentClass.indexOf('btn-back-all-class') !== -1) {
            item.word = 'Apagar tudo';
          }
          if (item.parentClass.indexOf('btn-enter-class') !== -1) {
            item.word = 'Enter';
          }
        }
      }
    }
    return keyboardAux;
  }
  generateSongPlayer(
    artist: string,
    name: string,
    advisor: string,
    namePlaylist: string,
    src: string,
    typeVideo: string,
    tones,
    uuid,
    video,
    icon,
    preroll
  ) {
    return {
      artist,
      name,
      advisor,
      namePlaylist,
      icon,
      preroll,
      sources: [
        {
          src,
          type: src.indexOf('.m3u8') === -1 ? typeVideo : 'application/x-mpegurl',
        },
      ],
      tones,
      uuid,
      video: src,
    };
  }

  generateKeyboardsPayment() {
    const monthKeyboard: any[] = [];
    const yearKeyboard: any[] = [];
    const cardKeyboard: any[] = [];
    let cvcKeyboard = [];

    for (let i = 0; i < 12; i++) {
      let letter;
      if (i < 9) {
        letter = '0' + (i + 1);
      } else {
        letter = String(i + 1);
      }
      monthKeyboard.push({
        word: letter,
      });

      const year = String(new Date().getFullYear() + i).substring(2);
      yearKeyboard.push({
        word: year,
      });
      if (i < 9) {
        cardKeyboard.push({
          word: String(i + 1),
        });
      } else if (i === 9) {
        cardKeyboard.push({
          word: String(0),
        });
      }
    }
    cvcKeyboard = Object.create(cardKeyboard);
    return {
      monthKeyboard,
      yearKeyboard,
      cardKeyboard,
      cvcKeyboard,
    };
  }
}
