import { Component, EventEmitter,Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mh-button',
  templateUrl: './mh-button.component.html',
  styleUrls: ['./mh-button.component.scss']
})
export class MhButtonComponent implements OnInit {

  @Input() text = 'Click Me';
  @Input() btnClass = '';
  @Input() btnId = '';
  @Output() clickAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  callback(event: any) {
    this.clickAction.emit(event);
  }
}
