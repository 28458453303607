/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryService } from '@providers/country/country';
import { isActionPlaying, isActionPlayingQueue } from '@providers/helpers/helpers';
import { RemotePlayerService } from '@providers/player';
import { SmartListService } from '@providers/smartlist';
import { SessionService } from '@providers/user/session.service';
import { of, Subject, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ABTestingService } from '../ab-testing/ab-testing.service';
import { Config } from '../config/config';
import { DeviceKS } from '../device-ks/device-ks';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { ModalService } from '../modal/modal';
import { Notifications } from '../notifications/notifications';
import { User } from '../user/user';
@Injectable()
export class FacebookLoginProvider {
  /**
   *  este servicio funciona durante el tiempo de validez del codigo que envia facebook
   *  que son aproximadamente 7 minutos despues de ese tiempo el usuario debera recargar la pagina
   *
   */
  urlFacebookLogin = 'https://graph.facebook.com/v7.0/device/login';
  urlFacebookLoginStatus = 'https://graph.facebook.com/v7.0/device/login_status';
  urlFacebookInfo = 'https://graph.facebook.com/v7.0/me';

  fbAppAccessToken = '225926810886925|5f51c6784104bf2bc2174715c8cc30da';
  fbAccessData;
  fbUserData: {
    id: string;
    name: string;
    email?: string;
    picture: { data: { height: number; is_silhouette: boolean; url: string; width: number } };
  };

  checkStatusInterval;
  attemptsGetCode = 0;
  userLoginFBOK: Subject<any> = new Subject();
  constructor(
    private abtesting: ABTestingService,
    private country: CountryService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorage,
    private config: Config,
    private modal: ModalService,
    private deviceks: DeviceKS,
    private metrics: MetricsService,
    private notification: Notifications,
    private sessionService: SessionService,
    private user: User,
    private remotePlayer: RemotePlayerService,
    private smartList: SmartListService
  ) {}

  cancelInterval() {
    clearInterval(this.checkStatusInterval);
  }
  setEmail(email) {
    this.fbUserData.email = email;
  }
  loginWithFacebook(): Promise<any> {
    if (this.fbUserData.email) {
      this.user.userLoginOK.next(true);
    }
    const deviceUid = this.deviceks.currentDUID;

    return this.user
      .login({
        email: this.fbUserData.email,
        first_name: this.fbUserData.name,
        tv_id: deviceUid,
        fb_id: this.fbUserData.id,
        codigo: this.user.getSessionQR(),
        is_bar: true,
        name_bar: 'kanto',
      })
      .toPromise()
      .then(async res => {
        // actualizando plans
        await this.localStorage.setItem('emailUsed', this.fbUserData.email);
        this.config.confData = this.config.confData ? this.config.confData : {};
        this.config.confData.plans = res.plans;
        this.sessionService.setSessionData(res);

        if (res.created) {
          this.metrics.sendEvent('createAccount', {
            idUser: this.user.currentUser.id,
            Premium: this.user.currentUser.premium,
            Email: this.user.currentUser.email,
            'Kanto Email': this.user.currentUser.email,
            'Where Session': 'facebook',
            caseABTest: this.user.currentUser['case_ab_testing'],
          });
        }

        this.metrics.sendEvent('openSesion', {
          idUser: this.user.currentUser.id,
          Premium: this.user.currentUser.premium,
          Email: this.user.currentUser.email,
          'Where Session': 'facebook',
          caseABTest: this.user.currentUser['case_ab_testing'],
        });
        // this.analytics.setDistinctId('update');
        this.modal.stateModal('', false);
        this.userLoginFBOK.next(true);
        if (this.user.currentUser.premium) {
          this.router.navigate(['toptrack']);
        } else if (this.config.countryIsNotFreemium(this.country.currentCountry)) {
          this.router.navigate(['subscription']);
        } else if (isActionPlaying(this.route.snapshot.queryParams.action)) {
          this.router.navigate(['player'], { queryParams: this.route.snapshot.queryParams });
        } else if (isActionPlayingQueue(this.route.snapshot.queryParams.action)) {
          this.remotePlayer.updateCurrentSong(this.smartList.smartList.list[0]).then(() => {
            this.router.navigate(['player']);
          });
        } else {
          this.router.navigate(['toptrack']);
        }
      })
      .catch(res => {
        if (res.code !== 100) {
          this.metrics.sendEvent('errorIngresa', {
            name: this.fbUserData.name,
            message: res.message,
            mode: 'facebook',
          });
          // Notificaciones:
          this.notification.notify('errorCheck', {
            message: res.message,
          });
        } else {
          // code de error
          let context = 'fb-without-email';
          if (
            this.route.snapshot.queryParams.context &&
            this.route.snapshot.queryParams.context.indexOf('b2500') !== -1
          ) {
            // en la url esta el id del plan en "ab2500+[idPlan]
            const posi = this.route.snapshot.queryParams.context.indexOf('-');
            if (posi !== -1) {
              const id = this.route.snapshot.queryParams.context.substring(posi + 1);

              this.router.navigate(['payment', 'checkout', id, 'tv']);

              context = context + '*' + id;
            }
          }
          this.router.navigate(['login', 'tv'], {
            queryParams: { context: 'fb-without-email' },
          });
        }
      });
  }

  getFBUserInfo() {
    return this.httpClient
      .post(this.urlFacebookInfo, {
        fields: 'name,email,picture,friends{first_name,last_name,email}',
        method: 'GET',
        access_token: this.fbAccessData.access_token,
      })
      .toPromise()
      .then((response: any) => {
        this.fbUserData = response;
        this.loginWithFacebook();
        return this.fbUserData;
      });
  }
  checkStatus(code) {
    return this.httpClient
      .post(this.urlFacebookLoginStatus, {
        access_token: this.fbAppAccessToken,
        code,
      })
      .pipe(
        switchMap((response: any) => {
          if (response.error) {
            return throwError(response.error);
          } else {
            return of(response);
          }
        })
      )
      .subscribe(
        (response: any) => {
          this.cancelInterval();
          this.fbAccessData = response;
          this.getFBUserInfo();
          return this.fbAccessData;
        },
        error => error
      );
  }
  init() {
    return this.httpClient
      .post(this.urlFacebookLogin, {
        access_token: this.fbAppAccessToken,
        scope: 'public_profile,email',
      })
      .toPromise()
      .then((response: any) => {
        const fbData = response;
        const timeToWait = (fbData.interval * 1 + 2) * 1000;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.checkStatusInterval ? clearInterval(this.checkStatusInterval) : '';
        this.checkStatusInterval = setInterval(() => {
          this.checkStatus(fbData.code);
        }, timeToWait);
        return fbData;
      })
      .catch(async err => {
        if (err.status === 403) {
          if (err.error.error.code === 4) {
            // eslint-disable-next-line no-throw-literal
            throw { name: 'facebook_not_available', code: 403, message: 'Facebook is not Available' };
          }
        }
        await this.tryAgainInit();
      });
  }

  async tryAgainInit() {
    this.attemptsGetCode++;
    if (this.attemptsGetCode < 3) {
      return this.init();
    } else {
      throw new Error('Max attempts');
    }
  }
  getFbUserData() {
    return this.fbUserData;
  }
}
