import { environment } from '@env/environment';
import { loadDynamicScript } from '@providers/dynamic-js-loader/dynamic-js-loader';
import { makeHttpRequest } from '@providers/helpers/backend';
import { fromEvent } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

import { Basic } from './device-platform';
import { AllowedDevicePlatformsType, IDevicePlatform } from './device-platform.interface';

interface VizioUserProfileResult {
  id: string;
  data: {
    customerId: string;
    email: string;
    firstName: string;
    keyId: string;
    lastName: string;
    message: string;
    signature: string;
    type: 'PARTNER_USER_PROFILE';
  };
}
interface VizioPurchaseResult {
  id: string;
  data: {
    success: boolean;
    achievedStage?: string;
    customerId: string;
    partnerAccountId: string;
    planCode: string;
    subscriptionId: string;
    errorCode?: string;
  };
}

// Vizio Documentation ---> https://docs.developer.vizio.com/v1/docs/side-load
export class Vizio extends Basic implements IDevicePlatform {
  //basic
  name = AllowedDevicePlatformsType.vizio;
  type = AllowedDevicePlatformsType.vizio;

  deviceUid = '';
  deviceObject: any = {};
  deviceData: any = {};

  VIZIO: any;

  signatureToken?: string;

  //vizio methods
  async onInit() {
    try {
      if (this.force) {
        this.deviceUid = `FORCED_Vizio-UID-1234`;
        this.deviceObject = {
          type: `FORCED_Vizio`,
          platform: 'vizio',
          modelName: 'FORCED_Vizio',
          serialNumber: 'FORCED_Vizio-SERIAL#-1234',
          vizio_api_version: 'FORCED_3.0.0-Vizio',
          vizio_is_purchase_capable: false,
        };

        this.deviceData = { ...this.deviceObject };
        super.onInit();

        return Promise.resolve(this.deviceObject);
      }

      await loadDynamicScript('http://localhost:12345/scfs/cl/js/vizio-companion-lib.js', 'vizioScript')
        .pipe(timeout(10000))
        .toPromise()
        .catch(err => {
          console.error('Error loading vizio script', err);
        });

      fromEvent(document, 'VIZIO_LIBRARY_DID_LOAD')
        .pipe(
          tap(() => {
            console.log('VIZIO_LIBRARY_DID_LOAD LOADED....');
            this.VIZIO = (window as any).VIZIO;
            this.deviceData.modelName = this.VIZIO.deviceInfo.model;
            this.VIZIO.getDeviceId(deviceId => {
              this.deviceData.deviceUID = deviceId;
            });
            if (this.VIZIO._deviceInfoStore.serialNumber) {
              this.deviceObject.serialNumber = 'VIZIO-' + this.VIZIO.deviceInfo.serialNumber;
              this.deviceData.serialNumber = 'VIZIO-' + this.VIZIO.deviceInfo.serialNumber;
              this.deviceUid = 'VIZIO-' + this.VIZIO.deviceInfo.serialNumber;
            }
            this.deviceData.vizio_api_version = this.VIZIO.deviceInfo.API_VERSION;
            this.deviceData.vizio_is_purchase_capable = this.VIZIO._deviceInfoStore.isPurchaseCapable;
            const isTest = environment.debug;
            const backendService = {
              signChallenge: async (challenge: string) => {
                let urlVizioToken = `${environment.firebase.functionsURL}/getVizioAppToken`;
                if (isTest) {
                  urlVizioToken = `${environment.firebase.functionsURL}/getVizioAppToken?test=true`;
                }
                return makeHttpRequest(urlVizioToken, 'POST', {
                  challenge,
                }).then(response => response.token);
              },
            };
            // Login Vizio APP on Vizio device
            this.VIZIO.Account.registerPartner(backendService.signChallenge, environment.vizio.appKey).then(() => {
              // console.log('Vizio App Registered');
              this.VIZIO.Account.getUserMetadata().then(
                (userMetaData: VizioUserProfileResult) => {
                  this.deviceUserInfo = userMetaData.data;
                },
                vizioResponseError => {
                  console.error(vizioResponseError);
                }
              );
            });
          })
        )
        .subscribe();

      // get vizio device id
    } catch (err) {
      console.log('error en vizio', err);
    }
    super.onInit();
  }

  //extends methods
  onResume(router) {
    //TODO: implementar onResume
  }

  exit() {
    this.VIZIO.exitApplication();
  }

  canPay() {
    if (!this.VIZIO) {
      return false;
    }
    return this.VIZIO._deviceInfoStore.isPurchaseCapable;
  }

  pay(data: { planType: 'monthly' | 'yearly' }): Promise<VizioPurchaseResult> {
    return new Promise((resolve, reject) => {
      const appId = 'kanto_karaoke';
      this.VIZIO.Account.getPlans()
        .then(vizioResponse => {
          const plans: { name: string; planCode: string; billingIntervalUnit: string }[] = vizioResponse.data;

          const vizioPlan = plans.find(plan => plan.name.toLowerCase().indexOf(data.planType) !== -1);
          if (!vizioPlan) {
            reject({ message: 'No se encontró el plan' });
            return;
          }

          const productId = vizioPlan.planCode;
          return this.VIZIO.Account.startSVODPurchase(productId, appId)
            .then((purchaseResult: VizioPurchaseResult) => {
              if (purchaseResult.data.success) {
                resolve(purchaseResult);
              } else {
                console.error('Failed purchase result', purchaseResult);
                reject(purchaseResult);
              }
            })
            .catch(err => {
              console.error('Error on startSVODPurchase', err);
              reject(err);
            });
        })
        .catch(err => {
          console.error('Error getting plans', err);
          reject(err);
        });
    });
  }
}
