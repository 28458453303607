import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnonymousSessionInterface } from '@interfaces/anonymous-session.interface';
import { IRemoteAction } from '@interfaces/remote-action.interface';
import { UserSessionResponseInterface } from '@interfaces/user-session-response.interface';
import {
  collection,
  doc,
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { documentFromOnSnapshot, valueChanges } from '@providers/helpers/firestore';
import { MetricsService } from '@providers/providers';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class RemoteSessionActionsService {
  currentActionsDoc: DocumentReference<IRemoteAction>;
  currentActionObservable: Observable<IRemoteAction>;

  currentActionSubscription: Subscription;

  remoteActions$: Subject<IRemoteAction> = new Subject<IRemoteAction>();

  constructor(private router: Router, private metrics: MetricsService) {}

  subscribeToRemoteActions(sessionDoc: DocumentReference<AnonymousSessionInterface | UserSessionResponseInterface>) {
    this.unsubscribeToRemoteActions();
    const actionsCollection = collection(sessionDoc, 'actions');
    this.currentActionsDoc = doc(actionsCollection, 'currentAction') as DocumentReference<IRemoteAction>;
    this.currentActionObservable = documentFromOnSnapshot(this.currentActionsDoc).pipe(valueChanges<IRemoteAction>());
    this.currentActionSubscription = this.currentActionObservable
      .pipe(filter(action => !action?.done))
      .subscribe(action => {
        this.processAction(action);
      });
  }

  async processAction(action: IRemoteAction): Promise<void> {
    switch (action.action) {
      case 'send_metrics_event': {
        this.sendMetricsEvent(action.data);
        break;
      }
    }

    const actionsForMarkingAsDoneInmediately = ['send_metrics_event'];
    if (actionsForMarkingAsDoneInmediately.includes(action.action)) {
      await this.markActionAsDone(action);
    }
  }

  sendMetricsEvent(data: any) {
    this.metrics.sendEvent(data.name, data.data);
  }

  unsubscribeToRemoteActions() {
    this.currentActionSubscription?.unsubscribe();
  }

  markActionAsDone(action: IRemoteAction): Promise<any> {
    return updateDoc(this.currentActionsDoc, {
      done: true,
      updatedAt: serverTimestamp(),
    });
  }
}
