import { Injectable } from '@angular/core';

import { ProfileArtistInterface } from '../../interfaces/profile-artist.interface';
import { Api } from '../api/api';

@Injectable()
export class ProfilesArtistService {
  listForPopularity: ProfileArtistInterface = null;
  listForPopularityWeek: ProfileArtistInterface = null;

  constructor(public api: Api) {}

  findArtistForWeek() {
    const promiseList = new Promise((resolve, reject) => {
      if (this.listForPopularityWeek) {
        resolve(this.listForPopularityWeek);
      } else {
        this.api.get('artist/top').subscribe(
          (res: any) => {
            this.listForPopularityWeek = res.data.artists;
            resolve(this.listForPopularityWeek);
          },
          err => {
            alert('Request failed: ' + err);
          }
        );
      }
    });
    return promiseList;
  }

  findArtistForPopularity() {
    const promiseList = new Promise((resolve, reject) => {
      if (this.listForPopularity) {
        resolve(this.listForPopularity);
      } else {
        this.api.get('user_profile/top').subscribe(
          (res: any) => {
            this.listForPopularity = res.data;
            resolve(this.listForPopularity);
          },
          err => {
            alert('Request failed: ' + err);
          }
        );
      }
    });
    return promiseList;
  }
}
