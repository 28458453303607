import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type AllowedDevicePlatforms =
  | 'webos'
  | 'netcast'
  | 'tizen'
  | 'zeasn'
  | 'basic'
  | 'chromecast'
  | 'movistar'
  | 'vizio'
  | 'unknown';

export enum AllowedDevicePlatformsType {
  'webos' = 'webos',
  'netcast' = 'netcast',
  'tizen' = 'tizen',
  'zeasn' = 'zeasn',
  'basic' = 'basic',
  'chromecast' = 'chromecast',
  'movistar' = 'movistar',
  'vizio' = 'vizio',
  'unknown' = 'unknown',
}

export interface ILaunchEventData {
  /**
   * Token de usuario.
   */
  tokenUser: string;

  /**
   * ID del dispositivo desde el que se conecta el usuario.
   */
  requestTokenId: string;

  /**
   * Status del Launch Event
   */
  done?: boolean;
}

export type ResolutionType = 'hd' | 'fhd';
export interface DeviceInfoForMetricsData {
  'TV ID': string;
  Brand?: string;
  Platform: string;
  'Network Type': string;
  'OSD Resolution': string;
  'Model Name': string;
  'App Version': string;
  'Build Number': string;
  'Allow Local Storage': boolean;
  'User Agent': string;
  OS?: string;
}
export interface IDevicePlatform {
  name: string;
  type: AllowedDevicePlatforms;
  channel?: string;
  deviceUid: string;
  year?: string;
  resolution?: ResolutionType;
  screenWidth?: number;
  screenHeight?: number;
  deviceUserInfo?: {
    email: string;
    firstName: string;
    lastName: string;
    [key: string]: any;
  };
  deviceObject?: any; // for <object> element
  deviceData?: any;
  deviceMixPanelData?: DeviceInfoForMetricsData;
  lauchEvent: BehaviorSubject<ILaunchEventData | undefined>;
  SDK?: any; // for native lib SDK

  $deviceEvent: Subject<{
    type: string;
    action?: string;
    data?: any;
  }>;

  onInit: () => Promise<void>;
  onLaunch: () => void;
  onRelaunch: () => void;
  onResume: (router?) => void;
  onMediaKeys?: () => void;
  exit: () => void;

  setNameWithOperatingSystem?: () => void;

  loadDeviceUid: () => void;
  loadDeviceParameters: () => void;

  enableVoiceCommands: (context?: string) => any;

  canPay: () => boolean;
  pay: (data: any) => Promise<any>;
}
