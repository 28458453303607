import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MhButtonComponent } from './mh-button.component';



@NgModule({
  declarations: [MhButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [MhButtonComponent]
})
export class MhButtonModule { }
