import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MovistarHomeComponent } from './movistar-home.component';
import { MhButtonModule } from 'src/components/mh-button/mh-button.module';
import { MovistarHomeRoutingModule } from './movistar-home.routing.module';



@NgModule({
  declarations: [
    MovistarHomeComponent,
  ],
  imports: [
    CommonModule,
    MhButtonModule,
    MovistarHomeRoutingModule,
  ],
  exports: [MovistarHomeComponent]
})
export class MovistarHomeModule { }
