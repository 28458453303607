import { Injectable } from '@angular/core';

@Injectable()
export class UserLastActionService {
  userLastAction = '';
  constructor() {}

  getUserLastAction() {
    return this.userLastAction;
  }

  setUserLastAction(action: string) {
    this.userLastAction = action;
  }
}
