import { Injectable } from '@angular/core';
import { AnonymousSessionInterface } from '@interfaces/anonymous-session.interface';
import { UserSessionResponseInterface } from '@interfaces/user-session-response.interface';
import { collection, doc, DocumentReference, updateDoc } from '@providers/firebase/firestore.functions';
import { documentFromOnSnapshot, valueChanges } from '@providers/helpers/firestore';
import { Subscription } from 'rxjs';

@Injectable()
export class RemoteConsoleService {
  remoteConsoleDoc: any;
  commandSubscription: Subscription;

  listenRemoteComands(sessionDoc: DocumentReference<AnonymousSessionInterface | UserSessionResponseInterface>) {
    this.unListenCommands();

    const remoteCollection = collection(sessionDoc, 'remote');
    this.remoteConsoleDoc = doc(remoteCollection, 'console');

    this.commandSubscription = documentFromOnSnapshot(this.remoteConsoleDoc)
      .pipe(valueChanges())
      .subscribe((consol: any) => {
        if (consol && consol.command) {
          this.executeRemote(consol.command);
        }
      });
  }

  executeRemote(command: string) {
    let result;

    try {
      // eslint-disable-next-line no-eval
      result = eval(command);
      if (typeof result === 'object') {
        result = JSON.stringify(result, Object.getOwnPropertyNames(result));
      }
    } catch (err) {
      result = JSON.stringify(err, Object.getOwnPropertyNames(err));
    }

    updateDoc(this.remoteConsoleDoc, { command: '', response: result || '' });
  }

  unListenCommands() {
    if (this.commandSubscription) {
      this.commandSubscription.unsubscribe();
    }
  }
}
