import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { IRemoteScoreStatus, RemoteScoreApplicationStatus } from '@interfaces/remote-score-status.interface';
import {
  collection,
  deleteField,
  doc,
  DocumentReference,
  serverTimestamp,
  setDoc,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { documentFromOnSnapshot, valueChanges } from '@providers/helpers/firestore';
import { SessionService } from '@providers/user/session.service';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class RemoteScoreStatusService {
  currentScoreStatusDoc: DocumentReference<IRemoteScoreStatus>;
  currentScoreStatus$: Observable<IRemoteScoreStatus>;
  currentScoreStatus: IRemoteScoreStatus;

  scoreStatusSubscription: Subscription;

  constructor(private db: AngularFireDatabase, private session: SessionService) {}

  subscribeToScoreStatus() {
    this.unsubscribeToScoreStatus();
    const smartListCollection = collection(this.session.sessionDoc, 'smartList');
    this.currentScoreStatusDoc = doc(smartListCollection, 'scoreStatus');
    this.currentScoreStatus$ = documentFromOnSnapshot(this.currentScoreStatusDoc).pipe(valueChanges());
    this.scoreStatusSubscription = this.currentScoreStatus$.subscribe(scoreStatus => {
      this.currentScoreStatus = scoreStatus;
    });
  }

  async updateScoreStatus(scoreStatus: Partial<IRemoteScoreStatus>) {
    scoreStatus.updatedAt = serverTimestamp();
    return setDoc(this.currentScoreStatusDoc, scoreStatus, { merge: true });
  }

  async setScoreState(state: RemoteScoreApplicationStatus) {
    if (state === 'TV_OUT_OF_SCORE' || state === 'TV_WAITING_MOBILE_CONNECTION_FOR_SCORE') {
      return this.updateScoreStatus({
        state,
        deviceId: deleteField() as any,
        deviceSelectedId: deleteField() as any,
        modelName: deleteField() as any,
        userId: deleteField() as any,
        userName: deleteField() as any,
      });
    }
    return this.updateScoreStatus({ state });
  }

  removeState() {
    return updateDoc(this.currentScoreStatusDoc, { state: deleteField() as any } as any);
  }

  unsubscribeToScoreStatus() {
    this.scoreStatusSubscription?.unsubscribe();
  }
}
