import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { User } from '@providers/user/user';

import { UserSessionResponseInterface } from '../interfaces/interfaces';

@Injectable()
export class CurrentUserResolver implements Resolve<Promise<UserSessionResponseInterface>> {
  constructor(private user: User) {}

  resolve() {
    const promiseCurrentUser: Promise<any> = new Promise((resolve, reject) => {
      this.user.onReady(() => {
        resolve(this.user.currentUser);
      });
    });
    return promiseCurrentUser;
  }
}
