import { Injectable } from '@angular/core';

import { Platform } from '../platform/platform';

export const transitionFallbackDuration = 500;
export const hideElement = el => {
  if (!el) {
    return;
  }

  const removeThis = () => {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  };

  el.classList.remove('show');
  el.classList.add('hide');
  el.addEventListener('transitionend', removeThis);

  // Fallback for no transitions.
  setTimeout(removeThis, transitionFallbackDuration);
};
@Injectable()
export class Alertify {
  parent: any;
  version = '1.0.12';
  defaultOkLabel = 'Ok';
  okLabel = 'Ok';
  defaultCancelLabel = 'Cancel';
  cancelLabel = 'Cancel';
  defaultMaxLogItems = 2;
  maxLogItems = 2;
  promptValue = '';
  promptPlaceholder = '';
  closeLogOnClick = false;
  closeLogOnClickDefault = false;
  delay = 5000;
  defaultDelay = 5000;
  logContainerClass = 'alertify-logs';
  logContainerDefaultClass = 'alertify-logs';
  logTemplateMethod: any;
  dialogs = {
    buttons: {
      holder: '<nav>{{buttons}}</nav>',
      ok: '<button class="ok" tabindex="1">{{ok}}</button>',
      cancel: '<button class="cancel" tabindex="2">{{cancel}}</button>',
    },
    input: '<input type="text">',
    message: '<p class="msg">{{message}}</p>',
    log: '<div class="{{class}}">{{message}}</div>',
  };

  defaultDialogs = {
    buttons: {
      holder: '<nav>{{buttons}}</nav>',
      ok: '<button class="ok" tabindex="1">{{ok}}</button>',
      cancel: '<button class="cancel" tabindex="2">{{cancel}}</button>',
    },
    input: '<input type="text">',
    message: '<p class="msg">{{message}}</p>',
    log: '<div class="{{class}}">{{message}}</div>',
  };
  constructor(platform: Platform) {
    if (platform.isBrowser()) {
      this.parent = document.body;
      this.injectCSS();
    }
  }
  /**
   * Build the proper message box
   *
   * @param item    Current object in the queue
   *
   * @return         An HTML string of the message box
   */
  build(item) {
    let btnTxt = this.dialogs.buttons.ok;
    let html = '<div class="dialog">' + '<div>' + this.dialogs.message.replace('{{message}}', item.message);

    if (item.type === 'confirm' || item.type === 'prompt') {
      btnTxt = this.dialogs.buttons.cancel + this.dialogs.buttons.ok;
    }

    if (item.type === 'prompt') {
      html += this.dialogs.input;
    }

    html = (html + this.dialogs.buttons.holder + '</div>' + '</div>')
      .replace('{{buttons}}', btnTxt)
      .replace('{{ok}}', this.okLabel)
      .replace('{{cancel}}', this.cancelLabel);

    return html;
  }

  setCloseLogOnClick(bool: boolean) {
    this.closeLogOnClick = !!bool;
  }

  /**
   * Close the log messages
   *
   * @param elem    HTML Element of log message to close
   * @param wait    [optional] Time (in ms) to wait before automatically hiding the message, if 0 never hide
   *
   * @return
   */
  close(elem, wait) {
    if (this.closeLogOnClick) {
      elem.addEventListener('click', () => {
        hideElement(elem);
      });
    }

    wait = wait && !isNaN(+wait) ? +wait : this.delay;

    if (wait < 0) {
      hideElement(elem);
    } else if (wait > 0) {
      setTimeout(() => {
        hideElement(elem);
      }, wait);
    }
  }

  /**
   * Create a dialog box
   *
   * @param message      The message passed from the callee
   * @param type         Type of dialog to create
   * @param onOkay       [Optional] Callback function when clicked okay.
   * @param onCancel     [Optional] Callback function when cancelled.
   * @param isValid      [Optional] Validate function for prompts
   *
   * @return
   */
  dialog(message, type, onOkay, onCancel, isValid) {
    return this.setup({
      message,
      type,
      onOkay,
      onCancel,
      isValid,
    });
  }

  /**
   * Show a new log message box
   *
   * @param message    The message passed from the callee
   * @param type       [Optional] Optional type of log message
   * @param wait       [Optional] Time (in ms) to wait before auto-hiding the log
   *
   * @return
   */
  log(message, type?, click?) {
    const existing = document.querySelectorAll('.alertify-logs > div');
    if (existing) {
      const diff = existing.length - this.maxLogItems;
      if (diff >= 0) {
        for (let i = 0, aux = diff + 1; i < aux; i++) {
          this.close(existing[i], -1);
        }
      }
    }

    this.notify(message, type, click);
  }

  setLogPosition(str) {
    this.logContainerClass = 'alertify-logs ' + str;
  }

  setupLogContainer() {
    let elLog = document.querySelector('.alertify-logs');
    const className = this.logContainerClass;
    if (!elLog) {
      elLog = document.createElement('div');
      elLog.className = className;
      this.parent.appendChild(elLog);
    }

    // Make sure it's positioned properly.
    if (elLog.className !== className) {
      elLog.className = className;
    }

    return elLog;
  }

  /**
   * Add new log message
   * If a type is passed, a class name "{type}" will get added.
   * This allows for custom look and feel for various types of notifications.
   *
   * @param message    The message passed from the callee
   * @param type       [Optional] Type of log message
   * @param wait       [Optional] Time (in ms) to wait before auto-hiding
   *
   * @return
   */
  notify(message, type, click) {
    const elLog = this.setupLogContainer();
    const log = document.createElement('div');

    log.className = type || 'default';
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.logTemplateMethod) {
      log.innerHTML = this.logTemplateMethod(message);
    } else {
      log.innerHTML = message;
    }

    // Add the click handler, if specified.
    if ('function' === typeof click) {
      log.addEventListener('click', click);
    }

    elLog.appendChild(log);
    setTimeout(() => {
      log.className += ' show';
    }, 10);

    this.close(log, this.delay);
  }

  /**
   * Initiate all the required pieces for the dialog box
   *
   * @return
   */
  setup(item: any) {
    const el: HTMLElement = document.createElement('div');
    el.className = 'alertify hide';
    el.innerHTML = this.build(item);

    const btnOK = el.querySelector('.ok') as HTMLElement;
    const btnCancel = el.querySelector('.cancel') as HTMLElement;
    const input = el.querySelector('input');
    const label = el.querySelector('label');

    const handleEscKey = event => {
      // 27 = Esc key
      if (event.which === 27) {
        // prompt/confirm have a cancel button
        if (btnCancel) {
          btnCancel.click();
        } else {
          // alert only has ok button
          btnOK.click();
        }
        document.removeEventListener('keyup', handleEscKey);
      }
    };
    document.addEventListener('keyup', handleEscKey);

    // Set default value/placeholder of input
    if (input) {
      if (typeof this.promptPlaceholder === 'string') {
        // Set the label, if available, for MDL, etc.
        if (label) {
          label.textContent = this.promptPlaceholder;
        } else {
          input.placeholder = this.promptPlaceholder;
        }
      }
      if (typeof this.promptValue === 'string') {
        input.value = this.promptValue;
      }
    }

    const isPrompt = () => input && item.type && item.type === 'prompt';

    const setupHandlers = (resolve?) => {
      if ('function' !== typeof resolve) {
        // promises are not available so resolve is a no-op
        resolve = () => {};
      }

      if (btnOK) {
        btnOK.addEventListener('click', ev => {
          if (isPrompt() && item.isValid && 'function' === typeof item.isValid) {
            if (!item.isValid(input.value)) {
              input.classList.add('invalid');
              return;
            }
          }

          if (item.onOkay && 'function' === typeof item.onOkay) {
            if (input) {
              item.onOkay(input.value, ev);
            } else {
              item.onOkay(ev);
            }
          }

          if (input) {
            resolve({
              buttonClicked: 'ok',
              inputValue: input.value,
              event: ev,
            });
          } else {
            resolve({
              buttonClicked: 'ok',
              event: ev,
            });
          }

          hideElement(el);
        });
      }

      if (btnCancel) {
        btnCancel.addEventListener('click', ev => {
          if (item.onCancel && 'function' === typeof item.onCancel) {
            item.onCancel(ev);
          }

          resolve({
            buttonClicked: 'cancel',
            event: ev,
          });

          hideElement(el);
        });
      }

      if (input) {
        input.addEventListener('keyup', ev => {
          // tslint:disable-next-line: deprecation
          if (ev.which === 13) {
            btnOK.click();
          }
        });
      }
    };

    let promise;

    if (typeof Promise === 'function') {
      promise = new Promise(setupHandlers);
    } else {
      setupHandlers();
    }

    this.parent.appendChild(el);
    setTimeout(() => {
      el.classList.remove('hide');
      if (isPrompt()) {
        input.select();
        input.focus();
      } else {
        if (btnOK) {
          btnOK.focus();
        }
      }
    }, 100);

    return promise;
  }

  okBtn(label) {
    this.okLabel = label;
    return this;
  }

  setDelay(time) {
    time = time || 0;
    this.delay = isNaN(time) ? this.defaultDelay : parseInt(time, 10);
    return this;
  }

  cancelBtn(str) {
    this.cancelLabel = str;
    return this;
  }

  setMaxLogItems(num?) {
    this.maxLogItems = parseInt(num || this.defaultMaxLogItems, 10);
  }

  theme(themeStr) {
    switch (themeStr.toLowerCase()) {
      case 'bootstrap':
        this.dialogs.buttons.ok = '<button class="ok btn btn-primary" tabindex="1">{{ok}}</button>';
        this.dialogs.buttons.cancel = '<button class="cancel btn btn-default" tabindex="2">{{cancel}}</button>';
        this.dialogs.input = '<input type="text" class="form-control">';
        break;
      case 'purecss':
        this.dialogs.buttons.ok = '<button class="ok pure-button" tabindex="1">{{ok}}</button>';
        this.dialogs.buttons.cancel = '<button class="cancel pure-button" tabindex="2">{{cancel}}</button>';
        break;
      case 'mdl':
      case 'material-design-light':
        this.dialogs.buttons.ok =
          '<button class="ok mdl-button mdl-js-button mdl-js-ripple-effect"  tabindex="1">{{ok}}</button>';
        this.dialogs.buttons.cancel =
          '<button class="cancel mdl-button mdl-js-button mdl-js-ripple-effect" tabindex="2">{{cancel}}</button>';
        this.dialogs.input =
          '<div class="mdl-textfield mdl-js-textfield">' +
          '<input class="mdl-textfield__input"><label class="md-textfield__label"></label></div>';
        break;
      case 'angular-material':
        this.dialogs.buttons.ok = '<button class="ok md-primary md-button" tabindex="1">{{ok}}</button>';
        this.dialogs.buttons.cancel = '<button class="cancel md-button" tabindex="2">{{cancel}}</button>';
        this.dialogs.input =
          '<div layout="column"><md-input-container md-no-float><input type="text"></md-input-container></div>';
        break;
      case 'default':
      default:
        this.dialogs.buttons.ok = this.defaultDialogs.buttons.ok;
        this.dialogs.buttons.cancel = this.defaultDialogs.buttons.cancel;
        this.dialogs.input = this.defaultDialogs.input;
        break;
    }
  }

  reset() {
    this.parent = document.body;
    this.theme('default');
    this.okBtn(this.defaultOkLabel);
    this.cancelBtn(this.defaultCancelLabel);
    this.setMaxLogItems();
    this.promptValue = '';
    this.promptPlaceholder = '';
    this.delay = this.defaultDelay;
    this.setCloseLogOnClick(this.closeLogOnClickDefault);
    this.setLogPosition('bottom left');
    this.logTemplateMethod = null;
  }

  injectCSS() {
    if (!document.querySelector('#alertifyCSS')) {
      const head = document.getElementsByTagName('head')[0];
      const css = document.createElement('style');
      // tslint:disable-next-line: deprecation
      css.type = 'text/css';
      css.id = 'alertifyCSS';
      css.innerHTML = '/* style.css */';
      head.insertBefore(css, head.firstChild);
    }
  }

  removeCSS() {
    const css = document.querySelector('#alertifyCSS');
    if (css && css.parentNode) {
      css.parentNode.removeChild(css);
    }
  }
}
