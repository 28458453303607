/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { MessageInterface } from '@interfaces/message.interface';
import { DeviceKS } from '@providers/device-ks/device-ks';
import { AllowedDevicePlatforms } from '@providers/device-ks/device-platform.interface';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { documentFromOnSnapshot, valueChanges } from '@providers/helpers/firestore';
import { forOwn, map as mapL } from 'lodash-es';
import { BehaviorSubject, from, Observable, Subject, Subscription, throwError } from 'rxjs';
import { debounceTime, defaultIfEmpty, filter, map, share, switchMap, switchMapTo, take, tap } from 'rxjs/operators';

import { IMedia, UserSessionResponseInterface } from '../../interfaces/interfaces';
import { MetricsService } from '../metrics/metrics.service';
import { Notifications } from '../notifications/notifications';
import { getSourceAndTone } from '../player/player.helpers';
import { PlayerService } from '../player/player.service';
import { SessionService } from '../user/session.service';
import { User } from '../user/user';
import { UserPlaylistService } from '../user/user-playlist.service';
import { SmartList } from './smart-list.interface';

@Injectable()
export class SmartListService {
  smartList: SmartList = { list: [] };
  smartListObservable: Observable<SmartList>;
  smarListSubscription: Subscription;
  currentSong$: BehaviorSubject<IMedia> = new BehaviorSubject(undefined);
  smartListDoc: DocumentReference<SmartList>;
  smartListCollection: CollectionReference<SmartList>;
  auxSmartList = [];
  subscribeToMessagesSubs: Subscription;
  subscribeToUserLoginSubs: Subscription;
  addSong: Subject<{ song: IMedia; user?: any; fromPage?: any }> = new Subject();
  addSongObservable: Observable<any>;
  IdPlaylistFavorite;

  fremiumSongsIds: number[] = [6905];

  get currentSong() {
    return this.currentSong$.value;
  }
  set currentSong(song) {
    this.currentSong$.next(song);
  }
  get currentSmartList() {
    return this.smartList.list;
  }
  set currentSmartList(list: IMedia[]) {
    this.smartList.list = list;
  }

  get currentSongsInSmartList() {
    return this.currentSmartList.length;
  }
  constructor(
    private notifications: Notifications,
    private user: User,
    private userPlaylist: UserPlaylistService,
    private metrics: MetricsService,
    private player: PlayerService,
    private session: SessionService,
    private deviceKS: DeviceKS
  ) {
    this.subscribeToSession();
    this.player.deleteSong.subscribe(song => {
      this.deleteSong(song);
    });

    this.addSongObservable = this.addSongToSmartListDebounce(this.addSong);
    this.addSongObservable.subscribe(() => {
      // console.log('this.querySearch', value);
    });
    this.IdPlaylistFavorite = this.userPlaylist.IdPlaylistFavorite;
  }

  subscribeToSession() {
    this.session.session$.subscribe(sessionData => {
      /* Player FireBase Doc */
      if (!sessionData) {
        return;
      }
      this.smartListCollection = collection(this.session.sessionDoc, 'smartList') as CollectionReference<SmartList>;
      this.smartListDoc = doc(this.smartListCollection, 'songs');
      this.smartListObservable = documentFromOnSnapshot(this.smartListDoc).pipe(valueChanges());
      if (this.smarListSubscription) {
        this.smarListSubscription.unsubscribe();
      }
      this.smarListSubscription = this.smartListObservable.subscribe(this.onSmartListChanges.bind(this));
      this.subscribeToMessages();
      this.subscribeToUserLogin();
    });
  }

  addSongToSmartList(
    song: IMedia,
    user?: UserSessionResponseInterface,
    quiet?: boolean,
    fromPage?: string
  ): Observable<SmartList> {
    if (user) {
      song.user_name = user.first_name || null;
      song.user_id = user.id || null;
    }

    if (!quiet) {
      this.notifications.notify('addSmartList', song);
      this.metrics.sendEvent('addSongToSmartList', song);
    }
    this.addSong.next({ song, user, fromPage });

    return this.smartListObservable;
  }

  addMultipleSongsToSmartList(
    songs: IMedia[],
    user?: UserSessionResponseInterface
  ): Observable<SmartList> {
    if (user) {
      songs.forEach(song => {
        song.user_name = user.first_name || null;
        song.user_id = user.id || null;
      });
    }

    songs.forEach(song => {
      this.addSongToSmartListFN({...song, originDevice: 'TV'}, user);
    });

    return this.smartListObservable;
  }

  addSongToSmartListDebounce(query: Observable<{ song: IMedia; user?: any; fromPage?: any }>) {
    return query.pipe(
      debounceTime(100),
      defaultIfEmpty(null),
      switchMap(data => from(this.addSongToSmartListFN(data.song, data.user, data.fromPage)))
    );
  }
  async addSongToSmartListFN(song: IMedia, user?: UserSessionResponseInterface, fromPage?: string): Promise<IMedia> {
    song.current_tone = song?.current_tone === undefined ? 0 : song?.current_tone;
    song = this.deleteAttrInvalid(song);
    if (user) {
      song.user_name = user.first_name;
      song.user_id = user.id;
      if (user.fb_id && !song.fb_id) {
        song.fb_id = user.fb_id;
      }
    }
    song.id = parseInt(song.id.toString(), 10);
    if (!song.uid) {
      song.uid = `${new Date().getTime()}_${song.id}_${Math.floor(Math.random() * 1000 + 1)}`;
    }
    // check song is not in the list
    const songExist = this.getSongById(song.id, song.user_id);
    if (!!songExist) {
      await this.updateSmartList();
      return songExist;
    }
    this.smartList.list.push(song);
    await this.updateSmartList();
    return song;
  }

  deleteAttrInvalid(song) {
    // firebase no allow attr null or undefined
    forOwn(song, (value, key) => {
      if (!song[key]) {
        delete song[key];
      }
    });
    return song;
  }

  removeGhostsSongs() {
    this.smartList.list = this.smartList.list.filter(song => song.ghost !== true);
    return this.updateSmartList();
  }

  updateSmartList(): Promise<SmartList> {
    return setDoc(this.smartListDoc, this.smartList).then(() => this.smartList);
  }

  songExistInSmartList(song: IMedia, userId: string | number): boolean {
    return this.getSongById(song.id, userId) !== undefined;
  }

  getSongById(id: number, userId: string | number): IMedia {
    return this.smartList.list.find(song => song.id === id && song.user_id === userId);
  }

  getSongByUid(uid: string): IMedia {
    return this.smartList.list.find(song => song.uid === uid);
  }

  getIndexByUid(song: IMedia) {
    return this.smartList.list.findIndex(x => x.uid === song.uid);
  }
  getIndexNextSong(song: IMedia) {
    return this.getIndexByUid(song) + 1;
  }
  deleteSong(song: IMedia, force?: boolean): Promise<any> {
    if (song.mode === 'game' && !force) {
      return Promise.resolve(false);
    }
    this.smartList.list.splice(this.getIndexByUid(song), 1);
    return this.updateSmartList();
  }

  async setReportSongState(song: IMedia, state: string) {
    this.smartList.list[this.getIndexByUid(song)].reportSong = state;
    await this.updateSmartList();
  }

  async moveSongToTop(song: IMedia): Promise<IMedia> {
    this.smartList.list.splice(this.getIndexByUid(song), 1);
    this.smartList.list.unshift(song);
    await this.updateSmartList();
    return song;
  }

  mapToFlowPlayer: (deviceType: AllowedDevicePlatforms) => (song: IMedia) => IMedia =
    (deviceType: AllowedDevicePlatforms) => (song: IMedia) => {
      let tone: string;
      if (song && song?.current_tone) {
        tone = song?.current_tone > 0 ? `+${song?.current_tone.toString()}` : song?.current_tone.toString();
      }
      console.log('la cancion llega con el tono', tone);
      if (!this.user.premium) {
        tone = '0';
      }
      song.sources = getSourceAndTone(song, tone, undefined, undefined, deviceType);
      return song;
    };

  getLastClip() {
    return this.smartList.list[this.smartList.list.length - 1];
  }
  getTimeInitApp() {
    const tiempoInicialCargaApp = parseInt((window as any).tiempoInicialCargaApp, 10);
    return Math.round((new Date().getTime() - tiempoInicialCargaApp) / 1000);
  }
  onSmartListChanges(res: SmartList) {
    console.log('res on smart list change', res);
    if (res && this.smartList.list.length < res.list.length) {
      const lastSong: IMedia = res.list[res.list.length - 1];
      if (!lastSong.analized) {
        if (lastSong.originDevice !== 'TV') {
          if (this.getTimeInitApp() > 10) {
            this.notifications.notify('addSmartListFriends', lastSong);
          }
          if(!lastSong.ghost) {
            this.metrics.sendEvent('addSongToSmartList', lastSong);
          }
        }
      } else {
        lastSong.analized = true;
        updateDoc(this.smartListDoc, res as any);
      }
    }
    if (res) {
      this.smartList = res;
      this.player.list = mapL(res.list, this.mapToFlowPlayer(this.deviceKS.type));
    }
  }

  subscribeToMessages() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.subscribeToMessagesSubs ? this.subscribeToMessagesSubs.unsubscribe() : null;

    this.subscribeToMessagesSubs = this.session.getMessagesObservable().subscribe(
      res => this.receiveMessages(res),
      err => this.failMessages(err)
    );
  }

  subscribeToUserLogin() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.subscribeToUserLoginSubs ? this.subscribeToUserLoginSubs.unsubscribe() : null;
    this.subscribeToUserLoginSubs = this.user.user$
      .pipe(
        filter(user => user !== undefined && user !== null),
        switchMap(user => getDoc(this.smartListDoc).then(smarlist => smarlist))
      )

      .subscribe(async smartList => {
        for (const song of this.smartList.list) {
          if (!song.user_id) {
            song.user_id = this.user.currentUser.id;
            song.user_name = this.user.currentUser.first_name;
            song.fb_id = this.user.currentUser.fb_id;
          }
        }
        await this.updateSmartList();
      });
  }
  receiveMessages(res: MessageInterface) {
    if (res) {
      const messageDoc = doc(this.session.messagesCollection, res.id);
      deleteDoc(messageDoc);
      this.metrics.sendEvent('messagesReceived', res);
    }
  }
  failMessages(err) {
    this.metrics.sendEvent('FailMessagesReceived', err);
  }
}
