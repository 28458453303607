// src/app/auth/token.interceptor.ts
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetricsService } from '@providers/metrics/metrics.service';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { DeviceKS } from '../device-ks/device-ks';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  url: string = environment.api;

  constructor(private metrics: MetricsService, private deviceKS: DeviceKS) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });*/
    const urlsToIgnore = [
      this.url + '/search',
      this.url + '/songs/?q',
      this.url + 'search',
      'https://graph.facebook.com/v7.0/device/login_status',
    ];

    const shouldBeIntercepted = (response: HttpResponse<any>) =>
      response &&
      response.status &&
      [200, 201].indexOf(response.status) === -1 &&
      response.url.indexOf(this.url + '/songs/?q') === -1 &&
      response.url.indexOf(this.url + 'search') === -1 &&
      urlsToIgnore.indexOf(response.url) === -1;

    const interception = next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse && shouldBeIntercepted(event)) {
          event = event.clone({ body: this.resolveReferences(event, request) });
        }
        return event;
      }),
      share()
    );
    interception.subscribe(
      () => {},
      error => {
        if (shouldBeIntercepted(error)) {
          this.resolveReferences(error, request);
        }
      }
    );
    return interception;
  }

  resolveReferences(response: HttpResponse<any> | HttpErrorResponse, request: HttpRequest<any>): any {
    try {
      this.metrics.sendEvent('errorRequest', {
        body: request.body,
        headers: request.headers,
        versionApp: environment.version,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      });
    } catch (e) {}
  }
}
