import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AppConfigInterface } from '@interfaces/app-config.interface';
import { collection, doc, Firestore, getDoc, mainCollection } from '@providers/firebase/firestore.functions';
import { mapValues } from 'lodash-es';
import { BehaviorSubject, from } from 'rxjs';
import { map, take, timeout } from 'rxjs/operators';

import { Platform } from '../platform/platform';

@Injectable()
export class RemoteConfigService {
  currentConfig$: BehaviorSubject<AppConfigInterface | undefined> = new BehaviorSubject<AppConfigInterface | undefined>(
    undefined
  );

  get currentConfig(): AppConfigInterface | undefined {
    return this.currentConfig$.value ? this.currentConfig$.value : undefined;
  }

  set currentConfig(config: AppConfigInterface | undefined) {
    this.currentConfig$.next(config);
  }

  constructor(private firestore: Firestore, private platform: Platform, private http: HttpClient) {}

  async load() {
    let promiseDefault;
    let promiseCustomerServicePhones;
    if (this.platform.isBrowser()) {
      // const firestore = this.fireBaseApp.firestore();
      const defaultCollection = mainCollection(this.firestore, environment.defaultConfig);
      const defaultRef = doc(defaultCollection, 'default');
      const customerServicePhonesRef = doc(defaultCollection, 'customerServicePhones');
      promiseDefault = from(getDoc(defaultRef))
        .pipe(timeout(5000))
        .toPromise()
        .then(snapshot => {
          const data: any = snapshot.data();
          if (data && data.promoCounter && data.promoCounter.seconds) {
            data.promoCounter = new Date(data.promoCounter.seconds * 1000);
          }
          const currentTime = data.promoCounter.getTime();
          const hoursZoneOffset = data.promoCounter.getTimezoneOffset() / 60;
          if (data) {
            data.promoCounter = new Date(currentTime + ((data?.gmtZone || 0) + hoursZoneOffset) * 3600 * 1000);
          }
          return data;
        })
        .catch(e => ({ customerServicePhones: {} }));
      promiseCustomerServicePhones = from(getDoc(customerServicePhonesRef))
        .pipe(timeout(5000))
        .toPromise()
        .then(snapshot => snapshot.data())
        .catch(e => ({}));
    } else {
      const urlFirestore = `https://firestore.googleapis.com/v1beta1/projects/${environment.firebase.projectId}/databases/(default)`;
      const urlConfigDefault = urlFirestore + '/documents/' + environment.defaultConfig + '/' + 'default';
      const urlcustomerServicePhones =
        urlFirestore + '/documents/' + environment.defaultConfig + '/' + 'customerServicePhones';
      promiseDefault = this.http
        .get(urlConfigDefault)
        .pipe(map((response: any) => mapValues(response.fields, value => value.stringValue), take(1)))
        .toPromise();
      promiseCustomerServicePhones = this.http
        .get(urlcustomerServicePhones)
        .pipe(map((response: any) => mapValues(response.fields, value => value.stringValue), take(1)))
        .toPromise();
    }
    return Promise.all([promiseDefault, promiseCustomerServicePhones])
      .then(([data, customerServicePhones]: [any, any]) => {
        this.currentConfig = { ...data, customerServicePhones };
      })
      .catch(e => {});
  }
}

export const remoteConfigFactory =
  (service: RemoteConfigService): (() => Promise<any>) =>
  () =>
    service.load();
