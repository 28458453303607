import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { CountryService } from '../providers/country/country';

@Injectable()
export class CountryResolver implements Resolve<Promise<any>> {
  constructor(private country: CountryService) {}

  static promiseResolver(resolver: CountryResolver): Promise<any> {
    return resolver.resolve();
  }

  resolve() {
    const promiseCountry: Promise<any> = new Promise((resolve, reject) => {
      this.country.onReady(() => {
        resolve(this.country.currentCountry);
      });
    });
    return promiseCountry;
  }
}
