import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Platform {
  mixpanel: any;
  isReady: boolean;
  distinct_id: Subject<string> = new Subject();
  constructor(@Inject(PLATFORM_ID) private platformId: unknown) {}

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  isServer() {
    return isPlatformServer(this.platformId);
  }
}
