import { Injectable } from '@angular/core';
import { Config } from '@providers/config/config';
import { CountryService } from '@providers/country/country';

@Injectable()
export class PagesService {
  // INITIAL_PAGE_ROUTE = 'welcome';
  initialRoute = 'welcome-video/image';
  constructor(private country: CountryService, private config: Config) {}

  getInitialRoute() {
    if (this.config.countryIsNotFreemium(this.country.currentCountry)) {
      this.initialRoute = 'welcome-video/start';
    }
    return this.initialRoute;
  }

  setNewInitialRoute(route) {
    this.initialRoute = route;
  }
}
