import { environment } from '@env/environment';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  AllowedDevicePlatformsType,
  DeviceInfoForMetricsData,
  IDevicePlatform,
  ILaunchEventData,
  ResolutionType,
} from './device-platform.interface';

export class Basic implements IDevicePlatform {
  name = 'Unknown';
  type = AllowedDevicePlatformsType.basic;
  deviceUid = '';
  resolution: ResolutionType = 'hd';
  screenWidth = 0;
  screenHeight = 0;

  deviceObject: any | undefined = undefined;
  deviceData = undefined;
  deviceMixPanelData = {} as DeviceInfoForMetricsData;

  $deviceEvent: Subject<any> = new Subject();

  relaunchSubscription;

  deviceUserInfo: {
    email: string;
    firstName: string;
    lastName: string;
    [key: string]: any;
  } = undefined;

  public readonly lauchEvent = new BehaviorSubject<ILaunchEventData | undefined>(undefined);

  constructor(protected force = false) {
    this.screenWidth = screen.width;
    this.screenHeight = screen.height;
    if (this.screenWidth > 1900) {
      this.resolution = 'fhd';
    }
    this.onLaunch();
    this.onRelaunch();
  }

  onLaunch() {}

  onRelaunch() {}

  detectOperatingSystem(): string {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Android') !== -1) {
      return 'android';
    } else if (
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('iPad') !== -1 ||
      userAgent.indexOf('iPod') !== -1
    ) {
      return 'ios';
    } else if (userAgent.indexOf('Windows Phone') !== -1) {
      return 'windowsPhone';
    } else if (userAgent.indexOf('Windows NT') !== -1) {
      return 'windows';
    } else if (userAgent.indexOf('Macintosh') !== -1) {
      return 'mac';
    } else if (userAgent.indexOf('Linux') !== -1) {
      return 'linux';
    } else {
      return 'unknow';
    }
  }

  setNameWithOperatingSystem() {
    const mobileDevices = ['android', 'ios', 'windowsPhone'];
    const desktopDevice = ['windows', 'mac', 'linux'];
    const osDetected = this.detectOperatingSystem();
    if (mobileDevices.indexOf(osDetected) !== -1) {
      this.name = 'Mobile Web';
    } else if (desktopDevice.indexOf(osDetected) !== -1) {
      this.name = 'TV Web';
    } else {
      this.name = 'Unknown';
    }
  }

  getNetworkType = networkType => {
    if (networkType === 0) {
      return 'Cableado';
    } else {
      return 'WiFi';
    }
  };

  getResolution = osdResolution => {
    switch (osdResolution) {
      case 0: {
        return '640x480';
        break;
      }
      case 1: {
        return '720x576';
        break;
      }
      case 2: {
        return '1280x720';
        break;
      }
      case 3: {
        return '1920x1080';
        break;
      }
      case 4: {
        return '1366x768';
        break;
      }
      default: {
        return this.screenWidth + 'x' + this.screenHeight;
      }
    }
  };

  loadDeviceUid() {
    this.deviceUid = this.deviceObject?.serialNumber || this.deviceData?.serialNumber;
    const storage = localStorage || (window as any).memoryStorage;
    if (!this.deviceUid) {
      this.deviceUid = storage.getItem('deviceUid');
      if (!this.deviceUid) {
        // generate a random device uuid
        this.deviceUid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
          // eslint-disable-next-line no-bitwise
          const r = (Math.random() * 16) | 0;
          // eslint-disable-next-line no-bitwise
          const v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
        storage.setItem('deviceUid', this.deviceUid);
      }
    }
  }

  loadDeviceParameters() {
    this.loadDeviceUid();
    const currentDUID = this.deviceUid;
    const networkType = this.getNetworkType(this.deviceObject?.networkType);
    const osdResolution = this.getResolution(this.deviceObject?.osdResolution);
    const lsTest = () => {
      const test = 'test';
      try {
        const storage = localStorage || (window as any).memoryStorage;
        storage.setItem(test, test);
        storage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    };

    const userAgent = navigator.userAgent;

    this.deviceMixPanelData = {
      'TV ID': currentDUID,
      Brand: this.name || 'UNDETECTED',

      Platform: this.deviceObject?.platform || this.name || 'UNDETECTED',
      'Network Type': networkType,
      'OSD Resolution': osdResolution,
      'Model Name': this.deviceObject?.modelName || this.deviceData?.modelName,
      'App Version': environment.version,
      'Build Number': environment.buildNumber,
      'Allow Local Storage': lsTest(),
      'User Agent': userAgent,
      // OS?: string
    };
  }

  async onInit() {
    this.loadDeviceUid();
  }

  onResume(router) {}

  enableVoiceCommands(context?: string) {
    return context;
  }

  canPay(): boolean {
    return false;
  }

  pay(data: any): Promise<any> {
    console.error('Pay not implemented');
    return Promise.reject('Pay not implemented');
  }

  exit() {
    window.history.go(-999);
    window.close();
  }
}
