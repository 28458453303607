import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
  isActive$ = new BehaviorSubject(false);

  get isActive() {
    return this.isActive$.value;
  }

  set isActive(value) {
    this.isActive$.next(value);
  }

  constructor() {}

  showLoading(): void {
    this.isActive$.next(true);
  }

  hideLoading(): void {
    this.isActive$.next(false);
  }
}
