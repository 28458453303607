import { Injectable } from '@angular/core';
import { proccessBackendResponse } from '@providers/helpers/backend';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IPlaylistInterface, ITopTrackPlaylistInterface } from '../../interfaces/toptrackList.interface';
/*import { AngularFirestore,AngularFirestoreDocument, AngularFirestoreCollection } from '@providers/firebase/firestore.functions';
import { Observable } from 'rxjs/Observable';*/
import { Api } from '../api/api';
// TODO: Corregir llamado de TranslateInitializer aqui
import { TranslateInitializer } from '../i18n/translate.initializer';

@Injectable()
export class PlaylistService {
  currentRecent = 91;
  currentRecommendations = 92;
  recent: any = {
    es: 91,
    pt: 711,
    en: 712,
  };

  recommendations: any = {
    es: 92,
    pt: 713,
    en: 714,
  };
  list: ITopTrackPlaylistInterface | null = null;
  weeklyPlaylist?: IPlaylistInterface;
  list2: any = null;
  PlayLists: any = [];
  songs: any = {};
  premiumSongs: any = [];
  loadFromResolve = true;
  haveFavorites = false;
  haveRecentPlayed = false;
  haveSuggestions = false;

  constructor(public api: Api, public translate: TranslateInitializer) {
    setTimeout(() => {
      const currentLang = this.translate.getCurrentLang();
      this.currentRecent = this.recent[currentLang];
      this.currentRecommendations = this.recommendations[currentLang];
    });
  }

  getCurrentRecent() {
    const id = this.recent[this.translate.getCurrentLang()];
    return id ? id : 91;
  }
  getCurrentRecommendations() {
    const id = this.recommendations[this.translate.getCurrentLang()];
    return id ? id : 92;
  }
  getPlaylistDetail(playlist_id: number): Promise<IPlaylistInterface> {
    // if (playlist_id>=90 && playlist_id<=92){
    return this.api
      .post('playlists/detail', { playlist_id })
      .toPromise()
      .then(res => res.data)
      .catch(err => {
        alert('Request failed: ' + err);
      });
  }

  getWeeklyPlaylist(): Promise<IPlaylistInterface> {
    if (this.weeklyPlaylist) {
      return of(this.weeklyPlaylist).toPromise();
    }
    return this.api
      .get('playlist_week')
      .pipe(
        switchMap(res => {
          if (res.success) {
            const playlist: IPlaylistInterface = res.data.playlist;
            playlist.img = playlist.img || res.data.img;
            this.weeklyPlaylist = playlist;
            return of(playlist);
          } else {
            return throwError(res);
          }
        })
      )
      .toPromise();
  }

  getPlaylistAddedBefore(list: any) {
    if (list.length) {
      const personalPlaylist = list.playlist_groups[0];
      personalPlaylist.playlists.forEach((e: any) => {
        switch (e.id) {
          case 90:
          case 709:
          case 710:
            this.haveFavorites = true;
            break;
          case 91:
            this.haveRecentPlayed = true;
            break;
          case 92:
            this.haveSuggestions = true;
            break;
        }
      });
    }
  }
  loadPLaylistFromResolve() {
    return this.loadFromResolve;
  }

  findAllPlayListGroups(): Observable<ITopTrackPlaylistInterface> {
    console.log('llame al findAllPlaylistGroup');
    if (this.list && this.loadPLaylistFromResolve()) {
      return of(this.list);
    } else {
      return this.api.get('playlist_groups').pipe(
        proccessBackendResponse<ITopTrackPlaylistInterface>(),
        map(data => {
          this.list = data;
          this.loadFromResolve = true;
          this.getPlaylistAddedBefore(this.list);
          return this.list;
        }),
        catchError(error => {
          console.log('error obteniendo data del servicio', error);
          return of(this.list);
        })
      );
    }
  }
}
