import { ILaunchEventData } from './device-platform.interface';

/**
 * Obtiene los parámetros de lanzamiento a partir del objeto Windows y valida que sean correctos.
 */
export const getLaunchEventData = (): ILaunchEventData | undefined => {
  const w = (window as unknown) as Partial<{
    PalmSystem: Partial<{ launchParams: Partial<ILaunchEventData> | string }>;
    launchParams: Partial<ILaunchEventData> | string;
  }>;

  // Fixme: Determinar en dónde y en qué formato se almacenan
  if (!w.launchParams && !w.PalmSystem) {
    return undefined;
  }

  let launchParams: any = w.PalmSystem?.launchParams ?? w.launchParams;

  if (typeof launchParams === 'string') {
    try {
      launchParams = JSON.parse(launchParams) as Partial<ILaunchEventData>;
    } catch (e) {
      // console.error('Unable to parse launch params', e, launchParams);
      launchParams = undefined;
      // throw new Error('Unable to parse launch data');
    }
  }

  // Comprobar que la estructura sea correcta
  if (!launchParams?.requestTokenId || !launchParams.tokenUser) {
    // console.error('Launch data is in a invalid state', launchParams);
    launchParams = undefined;
    // throw new Error('Launch data is in a invalid state');
  }

  return launchParams as ILaunchEventData;
};
