import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, map as mapL } from 'lodash-es';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: 'root',
})
export class FunctionsApi {
  url: string = environment.firebase.functionsURL;

  defaultHeaders: any = { headers: {} };
  defaultHeadersVal = {};

  constructor(public http: HttpClient) {}

  get(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = params;
    }
    return this.http.get(this.url + '/' + endpoint, this.validateRequestOptions(reqOpts));
  }

  post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post(this.url + '/' + endpoint, body, this.validateRequestOptions(reqOpts));
  }

  put(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put(this.url + '/' + endpoint, body, this.validateRequestOptions(reqOpts));
  }

  delete(endpoint: string, reqOpts?: any): Observable<any> {
    return this.http.delete(this.url + '/' + endpoint, this.validateRequestOptions(reqOpts));
  }

  patch(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put(this.url + '/' + endpoint, body, this.validateRequestOptions(reqOpts));
  }

  validateRequestOptions(reqOpts) {
    if (!reqOpts) {
      // console.log('validateRequestOptions 1')
      reqOpts = {
        headers: this.defaultHeaders.headers,
      };
    } else {
      // console.log('validateRequestOptions 2')
      reqOpts.headers = reqOpts.headers || this.defaultHeaders.headers;
    }
    return reqOpts;
  }
}
