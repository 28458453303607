import {
  AngularFirestore as Firestore,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/firestore';
import firebase from 'firebase/app';

const mainCollection: <T>(firestoreInstance: Firestore, path: string) => CollectionReference<T> = <T>(
  firestoreInstance: Firestore,
  path: string
) => {
  const collectionRef = firestoreInstance.firestore.collection(path) as CollectionReference<T>;

  return collectionRef;
};

const collection: <T>(documentRef: DocumentReference, path: string) => CollectionReference<T> = <T>(
  documentRef: DocumentReference,
  path: string
) => {
  const collectionRef = documentRef.collection(path) as CollectionReference<T>;

  return collectionRef;
};

const doc: <T>(
  collectionRef: CollectionReference<T>,
  documentPath?: string,
  ...pathSegments: string[]
) => DocumentReference<T> = <T>(
  collectionRef: CollectionReference<T>,
  documentPath?: string,
  ...pathSegments: string[]
) => {
  const segmentsString = pathSegments.reduce((acc, segment) => acc + '/' + segment, '');
  const documentRef = collectionRef.doc(documentPath + segmentsString) as DocumentReference<T>;
  return documentRef;
};

const getDoc: <T>(documentRef: DocumentReference<T>) => Promise<DocumentSnapshot<T>> = <T>(
  documentRef: DocumentReference<T>
) => {
  const promiseDocumentSnap = documentRef.get() as Promise<DocumentSnapshot<T>>;
  return promiseDocumentSnap;
};
const setDoc: <T>(documentRef: DocumentReference<T>, data: T, options?: { merge?: boolean }) => Promise<void> = <T>(
  documentRef: DocumentReference<T>,
  data: T,
  options?: { merge?: boolean }
) => {
  const promiseVoid = documentRef.set(data, options) as Promise<void>;
  return promiseVoid;
};
const updateDoc: <T>(documentRef: DocumentReference<T>, data: Partial<T>) => Promise<void> = <T>(
  documentRef: DocumentReference<T>,
  data: Partial<T>
) => {
  const promiseVoid = documentRef.update(data) as Promise<void>;
  return promiseVoid;
};
const deleteDoc: <T>(documentRef: DocumentReference<T>) => Promise<void> = <T>(documentRef: DocumentReference<T>) => {
  const promiseVoid = documentRef.delete() as Promise<void>;
  return promiseVoid;
};

const runTransaction: <T>(
  firestoreInstance: Firestore,
  updateFunction: (transaction: any) => Promise<T>
) => Promise<T> = <T>(firestoreInstance: Firestore, updateFunction: (transaction: any) => Promise<T>) => {
  const promiseT = firestoreInstance.firestore.runTransaction(updateFunction) as Promise<T>;
  return promiseT;
};
const serverTimestamp: () => any = () => {
  const any = firebase.firestore.FieldValue.serverTimestamp();
  return any;
};

const onSnapshot: <T>(
  query: DocumentReference<T> | CollectionReference<T>,
  options: { includeMetadataChanges: boolean },
  onNext: (snapshot: QuerySnapshot<T> | DocumentSnapshot<T>) => void
) => () => void = <T>(
  query: DocumentReference<T> | CollectionReference<T>,
  options: { includeMetadataChanges: boolean },
  onNext: (snapshot: QuerySnapshot<T> | DocumentSnapshot<T>) => void
) => {
  const unsubscribe = (query as any).onSnapshot(onNext);
  return unsubscribe;
};

const query: <T>(collectionRef: CollectionReference<T>, ...queryOptions: any[]) => CollectionReference<T> = <T>(
  collectionRef: CollectionReference<T>,
  ...queryOptions: any[]
) => {
  const querySnapshot = collectionRef;
  return querySnapshot;
};
const deleteField: () => any = () => {
  const any = firebase.firestore.FieldValue.delete();
  return any;
};

export {
  CollectionReference,
  DocumentChange,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/firestore';

export {
  collection,
  mainCollection,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  deleteField,
  runTransaction,
  Firestore,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  query,
};
