import { Injectable } from '@angular/core';
import { Api } from '@providers/api/api';
import { proccessBackendResponse } from '@providers/helpers/backend';

@Injectable()
export class PremiumCheckerService {
  constructor(private api: Api) {}

  check(token: string): Promise<{ premium: boolean }> {
    return this.api.get('user/premium', { u: token }).pipe(proccessBackendResponse<{ premium: boolean }>()).toPromise();
  }
}
