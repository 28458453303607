import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CurrentUserResolver } from 'src/resolvers/current-user.resolver';

import { appRoutes } from './app.routes';

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
  providers: [
    CurrentUserResolver,
    // { provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy }
    //    { provide: APP_BASE_HREF, useValue: '!' },
    //    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppRoutingModule {}
