import { ErrorHandler, Injectable } from '@angular/core';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { MetricsService } from '@providers/metrics/metrics.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private metrics: MetricsService, private localStorage: LocalStorage) {}
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\S]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      const wasReloaded = this.localStorage.getItem('wasReloaded');

      this.metrics.sendEvent('LoadingChunkError', { url: error.url, message: error.message });
      if (!wasReloaded) {
        this.localStorage.setItem('wasReloaded', true);
        this.metrics.sendEvent('AppReloaded', { url: error.url, message: error.message });
        window.location.reload();
      } else {
        this.metrics.sendEvent('AppRedirected', { url: error.url, message: error.message });
        window.location.href = 'https://app.kanto.co';
      }
    }
  }
}
